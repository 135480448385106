import { Injectable } from '@angular/core';
import { CompiereDataGridFilterType, CompiereDataGridType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { IupicsField } from '@iupics-manager/models/iupics-data';
import { cloneDeep } from 'lodash';
import { Observable, of } from 'rxjs';
import { finalize, share, switchMap } from 'rxjs/operators';
import { DataStoreService } from '../data-store/data-store.service';
import { SecurityManagerService } from '../security-manager/security-manager.service';

@Injectable()
export class CacheManagerService {
  static process_Params_caching: Map<number, any> = new Map();

  static productAttributes_caching: Map<string, IupicsField[]> = new Map();

  static iupics_components: Map<string, any> = new Map();

  static iupics_specific_window: Map<string, any> = new Map();

  static iupics_widgets: Map<string, any> = new Map();

  private _obsCache: Map<string, Observable<any>> = new Map<string, Observable<any>>();
  private _cache: Map<string, Map<any, any>> = new Map<string, Map<any, any>>();
  constructor(private dataStoreService: DataStoreService, private connectorService: SecurityManagerService) {}

  //Default Cache
  getCache(cacheName: CacheName | string): Map<number, any> {
    if (!this._cache.has(cacheName)) {
      this._cache.set(cacheName, new Map<any, any>());
    }
    return this._cache.get(cacheName);
  }
  setCache(cacheName: CacheName | string, value: any) {
    this._cache.set(cacheName, value);
  }

  getCacheValueById(cacheName: CacheName | string, id: any): any {
    return this.getCache(cacheName).get(id);
  }
  setCacheValueById(cacheName: CacheName | string, id: any, value: any) {
    this.getCache(cacheName).set(id, value);
  }

  /**
   *
   * @param obs Observable to share
   * @param obsIdentifier Unique identifier of observable
   * @param options {
   * id : Unique identifier within the cache defined,
   * cacheName : cache used,
   * getObsResultProcessing: Function which override getObsResultProcessing behaviour  }
   * @returns
   */
  getSharableObs(
    obs: Observable<any>,
    obsIdentifier: string,
    options: SharableObsOptions = {
      cached: false
    }
  ): Observable<any> {
    options = {
      ...{
        cached: false,
        cacheName: CacheName.DEFAULT
      },
      ...options
    };
    let observable: Observable<any>;
    const cached = options.cached;
    const cacheName = options.cacheName;
    const id = options.id;
    const getObsResultProcessing = options.getObsResultProcessing;

    if (cached && id === undefined) {
      throw new Error('Observable result could not be cached: id not defined.');
    }
    if (cached && this.getCacheValueById(cacheName, id) !== undefined) {
      observable = of(this.getCacheValueById(cacheName, id));
    } else if (this._obsCache.has(obsIdentifier)) {
      observable = this._obsCache.get(obsIdentifier);
    } else {
      observable = obs.pipe(
        switchMap((res) => {
          if (getObsResultProcessing) {
            return getObsResultProcessing(res, options);
          } else {
            return this.getObsResultProcessing(res, options);
          }
        }),
        share(),
        finalize(() => {
          this._obsCache.delete(obsIdentifier);
        })
      );
      this._obsCache.set(obsIdentifier, observable);
    }
    return observable;
  }
  private getObsResultProcessing(res: any, options: SharableObsOptions) {
    if (options.cached) {
      this.setCacheValueById(options.cacheName, options.id, res);
    }
    return of(res);
  }

  /**
   *get data from PO using sharable observable which avoids multiple same request
   * @param tableName must respect identifier ColumnName Case (C_Order => correct | c_order => incorrect )
   * @param id PO ID
   * @returns
   */
  getPOSharableObs(tableName: string, id: number): Observable<any> {
    let idExtracted = this.extractId(id);
    idExtracted = this.convertStringToNumber(idExtracted);
    const ad_language = this.connectorService.getIupicsDefaultLanguage().iso_code;
    const request: DataStoreRequest = {
      windowId: null,
      parent_constraint: '',
      compiereRequest: {
        windowType: CompiereDataGridType.TABLE,
        ad_language: ad_language,
        startRow: 0,
        endRow: 0,
        tableName: tableName,
        filterModel: {
          [tableName + '_ID']: {
            filterType: CompiereDataGridFilterType.SET,
            values: [idExtracted],
            operators: [OperatorFilterType.EQUALS]
          }
        }
      }
    };
    return this.getSharableObs(this.dataStoreService.getDataGrid(request), tableName + idExtracted, {
      cached: true,
      cacheName: tableName,
      id: idExtracted,
      getObsResultProcessing: (res: any, options: SharableObsOptions) => {
        this.setCacheValueById(options.cacheName, idExtracted, res.data[0]);
        return of(this.getCacheValueById(options.cacheName, idExtracted));
      }
    });
  }

  getPrecision(cacheName: CacheName, id: number): Observable<{}> {
    let precisionColumnName = 'StdPrecision';
    let tableName = 'C_Currency';
    switch (cacheName) {
      case CacheName.PRICE_LIST:
        tableName = 'M_PriceList';
        precisionColumnName = 'PricePrecision';
        break;
      case CacheName.UOM:
        tableName = 'C_UOM';
        break;
      case CacheName.CURRENCY:
        tableName = 'C_Currency';
        break;
      case CacheName.ACCT_SCHEMA:
        tableName = 'C_AcctSchema';
        break;
      default:
        break;
    }
    return this.getPOSharableObs(tableName, id).pipe(
      switchMap((res) => {
        if (res) {
          if (cacheName === CacheName.ACCT_SCHEMA) {
            return this.getPrecision(CacheName.CURRENCY, res['C_Currency_ID']);
          } else {
            return of(res[precisionColumnName]);
          }
        } else {
          return of(2);
        }
      })
    );
  }
  getCurrencySymbol(cacheName: CacheName, id: number): Observable<any> {
    let tableName = 'C_Currency';
    switch (cacheName) {
      case CacheName.PRICE_LIST:
        tableName = 'M_PriceList';
        break;
      case CacheName.CURRENCY:
        tableName = 'C_Currency';
        break;
      case CacheName.ACCT_SCHEMA:
        tableName = 'C_AcctSchema';
        break;
      default:
        break;
    }
    return this.getPOSharableObs(tableName, id).pipe(
      switchMap((res) => {
        if (res) {
          if (cacheName === CacheName.ACCT_SCHEMA || cacheName === CacheName.PRICE_LIST) {
            return this.getCurrencySymbol(CacheName.CURRENCY, res['C_Currency_ID']);
          } else {
            return of(res['CurSymbol']);
          }
        } else {
          return of(null);
        }
      })
    );
  }

  //Tools
  clearCache() {
    CacheManagerService.productAttributes_caching = new Map();
    CacheManagerService.process_Params_caching = new Map();
    this._cache = new Map<string, Map<any, any>>();
  }
  private extractId(valueToExtract: any): any {
    let value = cloneDeep(valueToExtract);
    if (value && value.id !== undefined) {
      value = value.id;
    }
    return value;
  }
  private convertStringToNumber(input: string) {
    if (!input) {
      return NaN;
    }
    if (typeof input !== 'number' && input.trim().length == 0) {
      return NaN;
    }
    return Number(input);
  }
}
export enum CacheName {
  DEFAULT = 'default',
  CURRENCY = 'C_Currency',
  PRICE_LIST = 'M_PriceList',
  ACCT_SCHEMA = 'C_AcctSchema',
  UOM = 'C_UOM'
}
export interface SharableObsOptions {
  cached: boolean;
  id?: any;
  cacheName?: CacheName | string;
  getObsResultProcessing?: Function;
}
