import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewEncapsulation } from '@angular/core';
import {
  CompiereDataGridFilterType,
  DataStore,
  DataStoreName,
  DataStoreRequest,
  DataStoreStatus
} from '@compiere-ws/models/compiere-data-json';
import { CompiereWorkflowService } from '@compiere-ws/services/compiere-workflow/compiere-workflow.service';
import { PoService } from '@compiere-ws/services/po/po.service';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDataContainer, LogicContextProperty } from '@iupics-manager/models/abstract-datacontainer';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { TranslateService } from '@ngx-translate/core';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { MenuItem } from 'primeng/api';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';

@Component({
  selector: 'iu-stepper-ui',
  templateUrl: './stepper-ui.component.html',
  styleUrls: ['./stepper-ui.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class StepperUiComponent extends AbstractDataContainer implements OnInit {
  @Output()
  refreshEdit: EventEmitter<any> = new EventEmitter();
  @Input()
  items: MenuItem[] = [];
  @Input()
  activeIndex = 0;

  @Input()
  isIndexNumber = true;

  @Input()
  preventPrevious = false;
  @Input()
  parent: EditViewUiComponent;

  @Input()
  editTab: EditTabUiComponent;
  @Input() changingStepper: Subject<any>;
  oldIndex: number;
  forbiddenStatus: string[] = ['CL', 'VO', 'RE'];
  cancelNext = false;
  subscription;
  isDisplay: boolean;
  //#region Custo
  itemId;
  windowCtx;
  XX_PrintZone_ID;
  index;
  //#endregion Custo

  constructor(
    public elementRef: ElementRef,
    public store: DataStoreService,
    protected connectorService: SecurityManagerService,
    public uiCreator: UICreatorService,
    public cmService: ContextMenuService,
    private workflowService: CompiereWorkflowService,
    renderer: Renderer2,
    private messageManager: MessageManagerService,
    private translateService: TranslateService,
    private dataStoreService: DataStoreService,
    protected po: PoService,
    protected cacheService: CacheManagerService,
    private progressService: ProcessInProgressService

  ) {
    super(elementRef, connectorService, cmService, store, uiCreator, renderer, po, cacheService);
  }

  ngOnInit() {
    if (this.changingStepper) {
      this.subscriptions.push(
        this.changingStepper.subscribe((currentStore) => {
          this.dataStored = currentStore;
          if (currentStore) {
            this.getNextStatus(currentStore);
          } else {
            this.oldIndex = 0;
            this.items = [];
          }
        })
      );
    }
    if (this.parent && this.parent.currentDataStoreKey) {
      const currentStore = this.store.getStore(this.parent.currentDataStoreKey, DataStoreName.CURRENT);
      this.dataStored = <DataStore>currentStore;
      this.getNextStatus(currentStore);
      this.oldIndex = this.activeIndex;
    }
  }

  /**
   * Récupère les status suivants disponible par rapport au status actuel ssi ce n'est pas un nouveau record
   */
  getNextStatus(currentStore?: any) {
    this.checkReadAndDisplay(currentStore);
    if (this.isDisplay) {
      if (!this.cancelNext) {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
        this.items = [];
        if (this.isDisplay) {
          if (this.isReadOnly) {
            if (!(currentStore.data.DocStatus instanceof Object)) {
              this.store
                .getAutocompleteDataById(this.fieldType, this.data['docStatusField']['fieldId'], currentStore.data.DocStatus)
                .subscribe((item) => {
                  this.items.push({ label: item[0].displayValue, id: item[0].id });
                });
            } else {
              this.items.push({ label: currentStore.data.DocStatus.displayValue, id: currentStore.data.DocStatus.id });
            }
          } else {
            this.subscription = this.workflowService
              .getNextStatus({
                docAction: currentStore.data.DocAction.id,
                docStatus: currentStore.data.DocStatus.id,
                isSOTrx: currentStore.data.IsSOTrx,
                orderType: '',
                processing: currentStore.data.Processing,
                record_ID: this.parent.currentDataStoreKey.recordId.split(',').pop(),
                table_ID: String(this.parent.editTabs[0].data.ADTableID)
              })
              .subscribe((nextStatus) => {
                this.items.push({
                  label: currentStore.data.DocStatus.displayValue,
                  id: currentStore.data.DocStatus.id
                });
                this.items.push(...nextStatus.map((status) => ({ label: status.Name, id: status.Value })));
                this.activeIndex = this.items.findIndex((status) => status.id === currentStore.data.DocStatus.id);
              });
          }
        }
      } else {
        this.cancelNext = false;
      }
    }
  }
  /**
   * Lance le workflow vers le status sélectionner
   * @param index
   */
  changeItem(index) {
    if (!this.isReadOnly && index > this.activeIndex) {
      this.index = index;
      this.activeIndex = index;
      this.isReadOnly = true;
      const itemId = this.items[index].id;
      if (this.parent && !this.dataStoreService.checkDataBeforeNewLine(this.parent.currentDataStoreKey)) {
        this.cancelNext = true;
        this.parent.beforeSave(null).then((result) => {
          this.runWF(itemId, this.parent.getCurrentContext());
        });
      } else {
        this.runWF(itemId, this.parent.getCurrentContext());
      }
    }
  }
  //#region  Custo
  runWF(itemId, windowCtx: any) {
    const dataStored = this.parent.editTabs.find((editTab) => editTab.tabId === this.parent.tabId).dataStored;
    if ('CO' === itemId && this.parent.container.infoComponent.windowId === 143 && this.editTab.tabId === 186) {
      if (
        this.editTab.dataStored &&
        this.editTab.dataStored.data &&
        this.editTab.dataStored.data['C_DocTypeTarget_ID'] &&
        this.editTab.dataStored.data['C_DocTypeTarget_ID'].id > 0
      ) {
        this.activeIndex = this.oldIndex;
        this.checkReadAndDisplay(dataStored);
        const DocType_request: DataStoreRequest = {
          windowId: null,
          parent_constraint: null,
          compiereRequest: {
            startRow: 0,
            tableName: 'C_DocType',
            filterModel: {
              C_DocType_ID: {
                filterType: CompiereDataGridFilterType.SET,
                values: [this.editTab.dataStored.data['C_DocTypeTarget_ID'].id],
                operators: [OperatorFilterType.EQUALS]
              }
            }
          }
        };
        this.store.getDataGrid(DocType_request).subscribe((res) => {
          if (res && res.data && res.data[0]['ISPRINTEDPROCESSING'] === 'Y' && res.data[0]['DOCBASETYPE'] === 'SOO') {
            this.itemId = itemId;
            this.windowCtx = windowCtx;
            this.parent.updateModalDisplay(
              { key: 'displayFormUI', value: true, sourceComponent: this },
              { key: 'formId', value: 1000346 }
            );
          } else {
            this.runWFExecute(itemId, windowCtx);
          }
        });
      }
    } else if ('CO' === itemId && this.parent.container.infoComponent.windowId === 170 && this.editTab.tabId === 259) {
      this.activeIndex = this.oldIndex;
      this.itemId = itemId;
      this.windowCtx = windowCtx;
      this.parent.updateModalDisplay(
        { key: 'displayFormUI', value: true, sourceComponent: this },
        { key: 'formId', value: 1000346 }
      );
    } else if ('CO' === itemId && this.parent.container.infoComponent.windowId === 195 && this.editTab.tabId === 330
                  && windowCtx['C_Order_ID'] != undefined && windowCtx['C_Order_ID'] != null) {
      this.activeIndex = this.oldIndex;
      this.itemId = itemId;
      this.windowCtx = windowCtx;
      this.parent.updateModalDisplay(
        { key: 'displayFormUI', value: true, sourceComponent: this },
        { key: 'formId', value: 1000346 }
      );
    } else {
      this.runWFExecute(itemId, windowCtx);
    }
  }

  runWFExecute(itemId, windowCtx: any) {
    const ad_process_id = this.parent.editTabs.find((editTab) => editTab.tabId === this.parent.tabId).data.workflowStatus
      .AD_Process_ID;
    const dataStored = this.parent.editTabs.find((editTab) => editTab.tabId === this.parent.tabId).dataStored;
    this.activeIndex = this.index;
    this.isReadOnly = true;
    const sub = this.workflowService
      .runWF({
        record_id: String(this.parent.currentDataStoreKey.recordId.split(',').pop()),
        windowCtx: windowCtx,
        action: String(itemId),
        table_id: String(this.parent.editTabs[0].data.ADTableID),
        ad_process_id: String(ad_process_id)
      })
      .subscribe((response) => {
        if (response) {
          if (response.Success === true) {
            const initialStatus = this.items[0];
            if (
              (response.Processed === 'N' && response.Message) ||
              (initialStatus && initialStatus.id === response.DocStatus.id)
            ) {
              this.messageManager.newMessage(
                new IupicsMessage(this.translateService.instant('generic.warning'), response.Message, 'warning')
              );
            }
            // 139600
            let editViews: EditViewUiComponent[];
            editViews = <EditViewUiComponent[]>(
              this.parent.container.DOMChildrenComponent.slice().filter((child) => child instanceof EditViewUiComponent)
            );
            if (editViews && editViews[0] && editViews[0].currentDataStoreKey) {
              this.subscriptions.push(
                this.parent.store.syncWithRemoteWindowData(editViews[0] ? editViews[0].currentDataStoreKey : null).subscribe((dataStore) => {
                  for (let i = 0; i < editViews.length; i++) {
                    if (i !== 0) {
                      dataStore && dataStore.data && dataStore.data.Processed === 'Y'
                        ? (editViews[i].menuBarDetailComponent.isReadOnly = true)
                        : (editViews[i].menuBarDetailComponent.isReadOnly = false);
                      editViews[i].menuBarDetailComponent.updateButtonLists();
                      editViews[i].refreshData(false);
                    } else {
                      editViews[i].refreshData(false);
                    }
                  }
                  if (this.XX_PrintZone_ID != null) {
                    const Process_request: DataStoreRequest = {
                      windowId: null,
                      parent_constraint: null,
                      compiereRequest: {
                        startRow: 0,
                        tableName: 'AD_Process',
                        filterModel: {
                          Value: {
                            filterType: CompiereDataGridFilterType.SET,
                            values: ['CompleteOrderPrintProcess'],
                            operators: [OperatorFilterType.EQUALS]
                          }
                        }
                      }
                    };
                    this.store.getDataGrid(Process_request).subscribe((res) => {
                      if (res && res.data && res.data[0]['AD_PROCESS_ID'] > 0) {
                        const paramsMap: ProcessParams = {
                          ad_process_id: res.data[0]['AD_PROCESS_ID'],
                          className: null,
                          record_id: parseInt(String(this.parent.currentDataStoreKey.recordId.split(',').pop()), 10),
                          tableName: 'C_Order',
                          tables: null,
                          params: {},
                          ad_tab_id: null,
                          windowCtx: this.getCurrentContext(dataStore)
                        };
                        paramsMap.params['XX_PrintZone_ID'] = this.XX_PrintZone_ID;
                        paramsMap.params['AD_Table_ID'] = this.parent.data.ADTableID;

                        this.subscriptions.push(this.uiCreator.executeProcess(paramsMap).subscribe((values) => {
                          editViews[0].saveDisabled = true;
                          editViews[0].isLoading=true;
                          const progressSub = this.progressService.watchProcessInProgress().subscribe((pings) => {
                            const me = this.connectorService.getIupicsUserAccount();
                            const ping = pings.find((p) => {
                              return p.AD_User_ID.id === me.id && p.AD_Process_ID.id === res.data[0]['AD_PROCESS_ID'];
                            });
                            if (ping && ping.Status === 'finish') {
                              this.parent.store.syncWithRemoteWindowData(editViews[0] ? editViews[0].currentDataStoreKey : null).subscribe((data) => {
                                editViews[0].saveDisabled = false;
                                editViews[0].isLoading = false;
                                editViews[0].refreshData(false);
                              });
                              progressSub.unsubscribe();   
                            }
                          });
                        }));
                      }
                      this.XX_PrintZone_ID = null;
                    });
                  }
                })
              );
            }
          } else {
            this.activeIndex = this.oldIndex;
            this.messageManager.newMessage(
              new IupicsMessage(this.translateService.instant('generic.warning'), response.Message || ' no response')
            );
          }
        }
        this.checkReadAndDisplay(dataStored);
        sub.unsubscribe();
      });
  }

  runWFPrintZone(printZone) {
    if (printZone && printZone.id > 0) {
      this.XX_PrintZone_ID = printZone.id;
    } else {
      this.XX_PrintZone_ID = -1;
    }
    this.runWFExecute(this.itemId, this.windowCtx);
  }
  //#endregion Custo
  onChildUpdate(event): void {}
  onSiblingUpdate(event: IupicsEvent) {}
  onRemoveComponent(event: IupicsEvent) {}

  /**
   * check si le champ doit être affiché ou readonly
   * @param dataStored
   */
  checkReadAndDisplay(dataStored: DataStore) {
    this.isFieldDisplay(dataStored).subscribe((displayed) => {
      this.isDisplay = displayed;
    });
    this.isReadOnly = this.isFieldReadOnly(dataStored);
  }

  isFieldDisplay(dataStored: DataStore, changedColumns?: any): Observable<boolean> {
    if (this.data.DisplayLogic) {
      const dataMapToTest = this.getCurrentContext(dataStored, false);
      const hasContextVariable = this.hasContextLogicVariable(LogicContextProperty.DISPLAY);
      let hasContextChanged = true;
      if (hasContextVariable && changedColumns) {
        hasContextChanged = this.verifyContextLogic(Object.keys(changedColumns), LogicContextProperty.DISPLAY);
      }
      if (hasContextChanged || this.getCurrentLogicContextValue(LogicContextProperty.DISPLAY) === undefined) {
        if (this.data.DisplayLogic && this.data.DisplayLogic.trim().toLowerCase().startsWith('@sql=')) {
          return this.uiCreatorService
            .getDBSelect(
              LogicEvaluator.replaceVariables(
                this.data.DisplayLogic.slice(5),
                this.connectorService.getIupicsUserContext(),
                this.getCurrentContext(dataStored, false)
              ),
              [],
              []
            )
            .pipe(
              map((data) => {
                this.updateCurrentLogicContextValue(LogicContextProperty.DISPLAY, data.length > 0);
                return data.length > 0;
              })
            );
        } else if (this.data['docActionField']['data'].DisplayLogic) {
          this.updateCurrentLogicContextValue(
            LogicContextProperty.DISPLAY,
            LogicEvaluator.evaluateLogic(dataMapToTest, this.data['docActionField']['data'].DisplayLogic)
          );
          return of(this.getCurrentLogicContextValue(LogicContextProperty.DISPLAY));
        }
      } else {
        return of(this.getCurrentLogicContextValue(LogicContextProperty.DISPLAY));
      }
    } else {
      return of(true);
    }
  }

  isFieldReadOnly(dataStored: DataStore): boolean {
    if (this.forbiddenStatus.includes(dataStored.data.DocStatus.id) || dataStored.status === DataStoreStatus.NEWRECORD) {
      return true;
    }

    if (
      this.data['docActionField']['data'].isAlwaysUpdatable !== undefined &&
      this.data['docActionField']['data'].isAlwaysUpdatable !== null &&
      this.data['docActionField']['data'].isAlwaysUpdatable === true
    ) {
      return false;
    }
    // Chez les scouts une fenetre avec processed true est readonly
    if (
      this.data['docActionField']['data'].IsReadOnly !== undefined &&
      this.data['docActionField']['data'].IsReadOnly !== null &&
      this.data['docActionField']['data'].IsReadOnly === true
    ) {
      return true;
    }

    // Gestion de la readonly lors de la modification
    if (this.data['docActionField']['data'].isUpdateable === false && dataStored) {
      return true;
    }

    if (!this.canUpdate(dataStored)) {
      return true;
    }

    if (
      this.data['docActionField']['data'].ReadOnlyLogic &&
      this.data['docActionField']['data'].ReadOnlyLogic.trim().toLowerCase().startsWith('@sql=')
    ) {
      this.uiCreatorService
        .getDBSelect(
          LogicEvaluator.replaceVariables(
            this.data['docActionField']['data'].ReadOnlyLogic.slice(5),
            this.connectorService.getIupicsUserContext(),
            this.getCurrentContext(dataStored, false)
          ),
          [],
          []
        )
        .subscribe((data) => {
          if (data.length > 0) {
            this.isReadOnly = true;
          } else {
            this.isReadOnly = false;
          }
        });
    } else if (
      this.data['docActionField']['data'].ReadOnlyLogic &&
      LogicEvaluator.evaluateLogic(this.getCurrentContext(dataStored, false), this.data['docActionField']['data'].ReadOnlyLogic)
    ) {
      return true;
    }
    if (
      dataStored.data.IsActive !== undefined &&
      dataStored.data.IsActive !== null &&
      dataStored.data.IsActive === 'N' &&
      !this.isAccordion
    ) {
      return true;
    }
    return false;
  }
}
export interface ProcessParams {
  className: string;
  ad_process_id: number;
  tableName: string;
  record_id: number;
  params: any;
  tables: any[];
  ad_tab_id: number;
  windowCtx: any;
}
