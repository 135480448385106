<p-progressBar
  *ngIf="!isModal && isLoading"
  [mode]="'indeterminate'"
  [value]="0"
  [showValue]="false"
  [styleClass]="'loading-progressbar'"
></p-progressBar>
<div class="p-col-12">
  <div class="iu-export-data-footer">
    <div class="p-col-12 apiz-p-grid"><ng-template #top></ng-template></div>
  </div>
</div>
<div class="iu-specific-btn-container p-col-12 apiz-p-grid" [ngClass]="[shouldAddPadding ? 'shouldAddPadding' : '']">
  <iu-button-ui
    *ngIf="gridViews && gridViews.length === 1 && hasInfoWindowGrid"
    class="iu-btn p-col-12 p-md-3 p-lg-2"
    (click)="$event.preventDefault(); $event.stopPropagation(); zoomOnLineSelected()"
    label="{{ 'contextmenu.zoom' | translate }}"
    icon="fa fa-search-plus"
    data-cy="form-button-zoom"
  >
  </iu-button-ui>
  <ng-template #vcrButtons></ng-template>

  <iu-button-ui
    *ngIf="isModal && gridViews && gridViews.length === 1 && hasInfoWindowGrid"
    class="p-col-12 p-md-3 p-lg-2"
    (click)="$event.preventDefault(); $event.stopPropagation(); setSelectedRowOnAutocomplete(); closeModalEmitter.emit()"
    label="{{ 'infodialog.ok' | translate }}"
    data-cy="form-button-validate"
  >
  </iu-button-ui>
  <iu-button-ui
    *ngIf="isModal"
    class="iu-btn btn-cancel p-col-12 p-md-3 p-lg-2"
    (click)="$event.preventDefault(); $event.stopPropagation(); close()"
    label="{{ 'process.cancel' | translate }}"
    icon="fa fa-ban"
  >
  </iu-button-ui>
</div>
