<div  class="boards-list" cdkDropListGroup>
  
  <p style="font-size: 14px; margin-bottom: 5px"  *ngIf="maxLimitReached"> 
    <i class="fas fa-info-circle"></i>
  {{ 'kanban.maxLimitReached' | translate }} {{this.maxLimit}}</p>
  <iu-kanban-board-ui
    *ngFor="let kanbanGroup of kanbanGroups"
    [tabId]="tabId"
    [tableName]="tableName"
    [container]="this.container"
    [kanbanGroup]="kanbanGroup"
    [isFlexDesign]="kanbanGroups.length == 1"
    [columns_display_AD]="this.columns_display_AD"
    [kanbanImageColumn]="this.kanbanImageColumn"
    [(draggedElement)]="draggedElement"
    (changeGroupEmitter)="queryDataStore()"
    (clickEmitter)="clickEmitter.emit($event)"
  ></iu-kanban-board-ui>
</div>

