// Remove after 3.0.0
import { CompiereDataGridFilterType } from '@compiere-ws/models/compiere-data-json';
import { CompiereLanguage } from '@compiere-ws/models/compiere-language-json';
import { CompiereParam } from '@compiere-ws/models/compiere-process-json';
import { CompiereField, CompiereFieldInfo, TabsEntityCompiereJSON, WindowCompiereJSON } from '@compiere-ws/models/window-json';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsField, IupicsTab, IupicsTableDataHeader, NumberType } from '@iupics-manager/models/iupics-data';
import { cloneDeep, isNil } from 'lodash';
import * as moment from 'moment';
import { UICreatorService } from '../ui-creator.service';

export class UICreatorUtils {
  private static fieldGroups: IupicsField[];
  private static dataStore: DataStoreService;
  private static uiCreatorService: UICreatorService;
  private static connectorService: SecurityManagerService;
  private static defaultLanguage: CompiereLanguage;

  public static setDataStore(dataStore: DataStoreService): void {
    this.dataStore = dataStore;
  }
  public static setUICreatorService(uiCreatorService: UICreatorService): void {
    this.uiCreatorService = uiCreatorService;
  }
  public static setConnectorService(connectorService: SecurityManagerService): void {
    this.connectorService = connectorService;
  }

  public static getComponentNameFromReference(referenceId: number, fieldLength: number = 0): string {
    /** NOT DEFINED */

    /** NOT DEFINED */

    /* Display Type 10	String	*/
    /* Display Type 14	Text	*/
    /* Display Type 40	URL	*/
    /* Display Type 36	File Path	*/
    /* Display Type 39 File Name	*/
    if (referenceId === 10 || referenceId === 14 || referenceId === 40 || referenceId === 38 || referenceId === 39) {
      if (referenceId === 14 || fieldLength > 254) {
        return 'InputTextareaUiComponent';
      } else {
        return 'InputTextUiComponent';
      }
    } else if (
      /* Display Type 11	Integer	*/
      /* Display Type 12	Amount	*/
      /* Display Type 13	ID	*/
      /* Display Type 22	Number	*/
      /* Display Type 29	Quantity	*/
      /* Display Type 37	CostPrice	*/
      referenceId === 11 ||
      referenceId === 12 ||
      referenceId === 13 ||
      referenceId === 22 ||
      referenceId === 29 ||
      referenceId === 37
    ) {
      return 'InputNumberUiComponent';
    } else if (referenceId >= 15 && referenceId <= 16) {
      /* Display Type 15	Date	*/
      /* Display Type 16	DateTime	*/
      return 'CalendarUiComponent';
    } else if (referenceId === 24) {
      /* Display Type 24	Time	*/
      return 'InputTimeUiComponent';
    } else if (
      (referenceId >= 17 && referenceId <= 19) ||
      referenceId === 30 ||
      referenceId === 42 ||
      referenceId === 27 ||
      referenceId === 31 ||
      referenceId === 25 ||
      referenceId === 35
    ) {
      /* Display Type 17	List	*/
      /* Display Type 18	Table	*/
      /* Display Type 19	TableDir	*/
      /* Display Type 30	Search	*/
      /* Display Type 27	Color   */
      /* Display Type 42	PrinterName	*/
      /* Display Type 31	Locator	*/
      /* Display Type 25	Account	*/
      /* Display Type 35	PAttribute	*/
      return 'AutocompleteUiComponent';
    } else if (referenceId === 20) {
      /* Display Type 20	YN	*/
      return 'InputSwitchUiComponent';
    } else if (referenceId === 21) {
      /* Display Type 21	Location	*/
      return 'InputLocationUiComponent';
    } else if (referenceId === 28 || referenceId === 46) {
      /* Display Type 28	Button	*/
      /* Display type 46 Link Button */
      return 'ButtonUiComponent';
    } else if (referenceId === 36 || referenceId === 34 || referenceId === 23) {
      /* Display Type 36	CLOB	*/
      /* Display Type 23	BLOB	*/
      /* Display Type 34	Memo	*/
      return 'InputTextareaUiComponent';
    } else if (referenceId === 32) {
      /* Display Type 32 Image	*/
      return 'InputImageUiComponent';
    } else if (referenceId === 45) {
      /* Display Type 45 Upload File */
      return 'InputFileUiComponent';
    } else if (referenceId === 77) {
      /* Display Type 77 Comment */
      return 'CommentUiComponent';
    } else {
      console.log(`No Component defined for AD_Reference_ID : ${referenceId}`);
      return undefined;
    }
  }

  public static transformTab(tab: TabsEntityCompiereJSON, windowCompiere: WindowCompiereJSON): IupicsTab {
    tab.tab.CtxArea = {
      ...(windowCompiere.CtxArea ? UICreatorUtils.formatCtxArea(windowCompiere.CtxArea) : undefined),
      ...(tab.tab.CtxArea ? UICreatorUtils.formatCtxArea(tab.tab.CtxArea) : undefined)
    };

    tab.tab.CtxArea = Object.keys(tab.tab.CtxArea).length === 0 ? undefined : tab.tab.CtxArea;

    const tabTransformed: IupicsTab = {
      tabId: tab.tab.AD_Tab_ID,
      tabLevel: tab.tab.TabLevel,
      isSingleRow: tab.tab.IsSingleRow,
      editView: {
        children: [],
        component: 'EditTabUiComponent',
        data: {
          positionEditTab: tab.tab.PositionEdit, // 1 tab.tab.PositionEdit
          colspan: tab.tab.ColspanEdit, // 2 tab.tab.ColspanEdit
          isCollapsable: tab.tab.IsCollapsedDetailView,
          isCollapsedDefault: tab.tab.IsDefaultCollapsed,
          IsDeleteable: tab.tab.IsDeleteable,
          label: tab.tab.Name,
          isSingleRow: tab.tab.IsSingleRow,
          tabLevel: tab.tab.TabLevel,
          nbCol: Global.isMobileWidth() ? 1 : parseInt(tab.tab.NbCol, 0),
          DisplayLogic: tab.tab.DisplayLogic,
          ctxArea: tab.tab.CtxArea,
          ADTableID: tab.tab.AD_Table_ID,
          IsReadOnly: tab.tab.IsReadOnly ? true : !windowCompiere.IsReadWrite,
          IsInsertRecord: tab.tab.IsInsertRecord,
          ReadOnlyLogic: tab.tab.ReadOnlyLogic,
          IsDeletableLogic: tab.tab.IsDeletableLogic,
          DocFilters: tab.tab.DocFilters,
          TaggedColumns: tab.tab.TaggedColumns,
          isView: tab.tab.IsView,
          TableName: tab.tab.TableName,
          stepperData: {},
          LinkColumnName : tab.tab.LinkColumnName
        }
      },
      gridView: {
        children: [],
        component: 'GridViewUiComponent',
        gridPaginator: true,
        data: {
          positionEditTab: tab.tab.TabLevel === 0 ? tab.tab.PositionEdit : tab.tab.PositionGrid, // 1 : 3 tab.tab.PositionEdit : tab.tab.PositionGrid

          colspan: tab.tab.TabLevel === 0 ? tab.tab.ColspanEdit : tab.tab.ColspanGrid, // 2 : 1 tab.tab.ColspanEdit : tab.tab.ColspanGrid
          isCollapsable: tab.tab.IsCollapsedDetailView,
          isCollapsedDefault: tab.tab.IsDefaultCollapsed,
          IsDeleteable: tab.tab.IsDeleteable,
          label: tab.tab.Name,
          columnsTableHeader: [],
          DisplayLogic: tab.tab.DisplayLogic,
          hasTree: tab.tab.HasTree,
          urlTree: tab.url,
          items: [],
          AD_window_ID: windowCompiere.AD_Window_ID,
          ADTabID: tab.tab.AD_Tab_ID,
          ADTableID: tab.tab.AD_Table_ID,
          IsReadOnly: tab.tab.IsReadOnly ? true : !windowCompiere.IsReadWrite,
          IsDeletableLogic: tab.tab.IsDeletableLogic,
          DocFilters: tab.tab.DocFilters,
          TaggedColumns: tab.tab.TaggedColumns,
          IsInsertRecord: tab.tab.IsInsertRecord,
          isView: tab.tab.IsView,
          ReadOnlyLogic: tab.tab.ReadOnlyLogic,
          columns_display_AD: [
            { dataAlignLeft: [], dataAlignright: [] },
            { dataAlignLeft: [], dataAlignright: [] }
          ],
          kanbanImageColumn: null,
          columnsReduceGrid: [],
          TableName: tab.tab.TableName,
          LinkColumnName : tab.tab.LinkColumnName
        }
      }
    };
    this.fieldGroups = [];
    const dataFilter: string[] = [];
    const dataValidator: string[] = [];
    if (tab.tab.LinkColumnName) {
      const linkColumn = tab.fields.find((field) => field.field.ColumnName === tab.tab.LinkColumnName);
      if (linkColumn) {
        if (this.getFilterTypeFromReference(linkColumn.field.AD_Reference_ID) === CompiereDataGridFilterType.TEXT) {
          dataFilter.push(linkColumn.field.ColumnName + '="@' + linkColumn.field.ColumnName + '@"');
        } else {
          dataFilter.push(linkColumn.field.ColumnName + '=@' + linkColumn.field.ColumnName + '@');
        }
      } else {
        dataFilter.push(tab.tab.LinkColumnName + '=@' + tab.tab.LinkColumnName + '@');
      }
    }
    const dataStructure = { Data_UUID: null };
    const dataAlignLeftTL = [];
    const dataAlignrightTR = [];
    const dataAlignLeftBL = [];
    const dataAlignLeftBR = [];
    tab.fields.forEach((field) => {
      const fieldTransformed = this.transformField(
        field,
        windowCompiere.Name,
        { tabName: tab.tab.Name, tableName: tab.tab.TableName, linkColumnName: tab.tab.LinkColumnName },
        tab.tab.IsReadOnly ? true : !windowCompiere.IsReadWrite
      );
      if (fieldTransformed) {
        tabTransformed.editView.children.push(fieldTransformed);
      }
      /*On regarde si la colonne doit être affiché dans le kanban */
      if (field && field.field && field.field.DisplayKanban) {
        switch (field.field.DisplayKanban.substring(0, 2)) {
          case 'IM':
            tabTransformed.gridView.data.kanbanImageColumn = field.field.ColumnName;
            break;
          case 'TL':
            dataAlignLeftTL.push({ seqNo: field.field.DisplayKanban.substring(2, 1), columnName: field.field.ColumnName });
            break;
          case 'TR':
            dataAlignrightTR.push({ seqNo: field.field.DisplayKanban.substring(2, 1), columnName: field.field.ColumnName });
            break;
          case 'BL':
            dataAlignLeftBL.push({ seqNo: field.field.DisplayKanban.substring(2, 1), columnName: field.field.ColumnName });
            break;
          case 'BR':
            dataAlignLeftBR.push({ seqNo: field.field.DisplayKanban.substring(2, 1), columnName: field.field.ColumnName });
            break;
        }
      }
      if (!tab.tab.LinkColumnName && field.field.IsParent) {
        if (this.getFilterTypeFromReference(field.field.AD_Reference_ID) === CompiereDataGridFilterType.TEXT) {
          dataFilter.push(field.field.ColumnName + '="@' + field.field.ColumnName + '@"');
        } else {
          dataFilter.push(field.field.ColumnName + '=@' + field.field.ColumnName + '@');
        }
      }
      if (tab.tab.WhereClause) {
        dataValidator.push(tab.tab.WhereClause);
      }

      /*On tri les colonnes à afficher dans les kanbans */
      dataAlignLeftTL.sort((a, b) => (a.seqNo > b.seqNo ? 1 : b.seqNo > a.seqNo ? -1 : 0));
      dataAlignrightTR.sort((a, b) => (a.seqNo > b.seqNo ? 1 : b.seqNo > a.seqNo ? -1 : 0));
      dataAlignLeftBL.sort((a, b) => (a.seqNo > b.seqNo ? 1 : b.seqNo > a.seqNo ? -1 : 0));
      dataAlignLeftBR.sort((a, b) => (a.seqNo > b.seqNo ? 1 : b.seqNo > a.seqNo ? -1 : 0));
      /*On set les colonnes à afficher dans les kanbans */
      tabTransformed.gridView.data.columns_display_AD[0].dataAlignLeft = dataAlignLeftTL.map((data) => {
        return data.columnName;
      });
      tabTransformed.gridView.data.columns_display_AD[0].dataAlignright = dataAlignrightTR.map((data) => {
        return data.columnName;
      });
      tabTransformed.gridView.data.columns_display_AD[1].dataAlignLeft = dataAlignLeftBL.map((data) => {
        return data.columnName;
      });
      tabTransformed.gridView.data.columns_display_AD[1].dataAlignright = dataAlignLeftBR.map((data) => {
        return data.columnName;
      });

      // * uniquement constante
      const regexDV = new RegExp(/@\S*?@/g);
      if (
        field.field.DefaultValue !== undefined &&
        field.field.DefaultValue !== null &&
        !field.field.DefaultValue.toLowerCase().trim().startsWith('@sql=') &&
        !regexDV.test(field.field.DefaultValue) &&
        !['null', 'NULL'].includes(field.field.DefaultValue) &&
        (['DocAction', 'DocStatus'].includes(field.field.ColumnName) ||
          this.getComponentNameFromReference(field.field.AD_Reference_ID, 0) !== 'AutocompleteUiComponent')
      ) {
        dataStructure[field.field.ColumnName] = field.field.DefaultValue;
      } else {
        dataStructure[field.field.ColumnName] = null;
      }
    });

    if (windowCompiere.CtxArea) {
      Object.keys(windowCompiere.CtxArea).forEach((key) => {
        dataStructure[key] = windowCompiere.CtxArea[key];
      });
    }
    if (tab.tab.CtxArea) {
      Object.keys(tab.tab.CtxArea).forEach((key) => {
        dataStructure[key] = tab.tab.CtxArea[key];
      });
    }

    const tabHeader = cloneDeep(tab);
    tabHeader.fields
      .sort(function (a, b) {
        return a.field.MRSeqNo - b.field.MRSeqNo;
      })
      .forEach((field) => {
        const columnHeader = this.transformFieldForGrid(field.field);
        if (columnHeader) {
          tabTransformed.gridView.data.columnsTableHeader.push(columnHeader);
          if (field.field.IsDisplayReduceGrid) {
            tabTransformed.gridView.data.columnsReduceGrid.push(field.field.ColumnName);
          }
        }
      });
    this.dataStore.addWindowDataStructure(tab.tab.AD_Tab_ID, dataStructure);
    this.fieldGroups = [];
    tabTransformed.gridView.gridTabFilter = dataFilter;
    tabTransformed.editView.gridTabFilter = dataFilter;
    tabTransformed.gridView.gridTabValidator = dataValidator;
    tabTransformed.editView.gridTabValidator = dataValidator;
    if (tabTransformed.editView.children.length > 0 && tabTransformed.editView.children[0].component === 'AccordionUiComponent') {
      tabTransformed.editView.children[0].data.isSelected = true;
    }
    return tabTransformed;
  }

  public static transformField(
    fieldEntity: CompiereField | CompiereParam,
    windowName?: string,
    tabInfo?: any,
    tabReadOnly?: boolean,
    fromProcess: boolean = false
  ): IupicsField {
    const field = fieldEntity.field;
    if (field.IsParent || (tabInfo && tabInfo.linkColumnName && tabInfo.linkColumnName === field.ColumnName)) {
      field.DefaultValue = '@' + field.ColumnName + '@';
    }
    const newComponent = UICreatorUtils.getComponentNameFromReference(field.AD_Reference_ID, field.DisplayLength);
    if (newComponent === 'CalendarUiComponent') {
      this.dataStore.saveDateColumn(field.ColumnName, field.AD_Reference_ID);
    }
    if (newComponent === 'ButtonUiComponent' && field.AD_Form_ID) {
      this.uiCreatorService.loadSpecificWindow(field.AD_Form_ID);
    }
    if (newComponent) {
      if (fieldEntity.callouts) {
        fieldEntity.callouts.fieldId = field.AD_Field_ID;
        // MODIFY THIS SHIT
        fieldEntity.callouts.tabId = fieldEntity.callouts['idtab'];
        fieldEntity.callouts.windowId = 1000234;
        // MODIFY THIS SHIT
      }
      const fieldTransformed: IupicsField = {
        columnId: field.AD_Column_ID,
        fieldId: field.AD_Field_ID,
        processId: field.AD_Process_ID,
        formId: field.AD_Form_ID,
        referenceId: field.AD_Reference_ID,
        displayLength: field.DisplayLength,
        isCallout: field.IsCallout,
        seqNo: field.SeqNo,
        isReadOnly: tabReadOnly !== null && tabReadOnly !== undefined ? tabReadOnly : field.IsReadOnly,
        isRange: field.isRange,
        data: {
          fieldId: field.AD_Field_ID,
          columnId: field.AD_Column_ID,
          formId: field.AD_Form_ID,
          isUpdateable: field.IsUpdateable,
          isAlwaysUpdatable: tabReadOnly ? false : field.IsAlwaysUpdateable,
          isSameLine: field.IsSameLine,
          label: field.Name ? field.Name : field.name,
          fieldType: field.IsEncryptedColumn || field.IsEncryptedField ? 'password' : 'text',
          isCopy: field.IsCopy,
          items: fieldEntity.data,
          isMandatory: field.IsMandatoryUI,
          description: field.Description,
          columnName: field.ColumnName,
          DisplayLogic: !field.IsDisplayed ? '1=2' : field.DisplayLogic,
          IsDisplayed: field.IsDisplayed,
          mandatoryLogic: field.MandatoryLogic,
          ReadOnlyLogic: tabReadOnly ? '1=1' : field.IsReadOnly ? '1=1' : field.ReadOnlyLogic,
          nbrAddressSlot: 1,
          details: fieldEntity.details,
          urlList: fieldEntity.urlList,
          urlSearch: fieldEntity.urlSearch,
          urlCallouts: fieldEntity.urlCallouts,
          callouts: fieldEntity.callouts,
          tableName: tabInfo && tabInfo.tableName ? tabInfo.tableName : null,
          calloutLabel:
            windowName && tabInfo && tabInfo.tabName ? windowName + ' / ' + tabInfo.tabName + ' / ' + field.Name : null,
          tabId: field.AD_Tab_ID,
          isSearch: field.AD_Reference_ID === 30 || (fieldEntity.urlSearch !== null && fieldEntity.urlSearch !== undefined),
          isAccount: field.AD_Reference_ID === 25,
          needTime: field.AD_Reference_ID === 16 || field.AD_Reference_ID === 24,
          defaultValue: field.DefaultValue,
          searchColumns: fieldEntity.details ? fieldEntity.details.searchColumns : null,
          nbCol: Global.isMobileWidth() ? 1 : parseInt(field.ColNo, 0),
          marginTop: field.MarginTop,
          colspan: field.ColspanEdit,
          numberType:
            field.AD_Reference_ID === 12 || field.AD_Reference_ID === 37
              ? NumberType.AMOUNT
              : field.AD_Reference_ID === 11
              ? NumberType.INTEGER
              : field.AD_Reference_ID === 22
              ? NumberType.FLOAT
              : field.AD_Reference_ID === 29
              ? NumberType.QUANTITY
              : null,
          validationCode: field.ValidationCode ? field.ValidationCode.replace(/\n/g, ' ') : null,
          detailZoom:
            fieldEntity.details && (field.AD_Reference_ID === 30 || field.AD_Reference_ID === 19 || field.AD_Reference_ID === 18)
              ? {
                  tableName: fieldEntity.details.tableName,
                  columnKey: fieldEntity.details.keyColumn
                }
              : null,
          displayKanban: field.DisplayKanban,
          componentName: newComponent,
          //#region Custo
          ZoomTab: field.Z_ZoomTab,
          IsDefaultFocus : field.IsDefaultFocus
          ////#endregion Custo
        },
        component: newComponent
      }; // 17 list, 18 table, 19 table dir , 30 search
      if (field.IsDisplayed && field.FieldGroup) {
        let fieldGroup = null;
        this.fieldGroups.forEach((fieldGroupIterate) => {
          if (fieldGroupIterate.data.label === field.FieldGroup) {
            fieldGroup = fieldGroupIterate;
            return;
          }
        });
        if (fieldGroup) {
          fieldGroup.children.push(fieldTransformed);
          return undefined;
        } else {
          fieldGroup = {
            columnId: field.AD_Column_ID,
            fieldId: field.AD_Field_ID,
            processId: field.AD_Process_ID,
            formId: field.AD_Form_ID,
            referenceId: field.AD_Reference_ID,
            displayLength: field.DisplayLength,
            isCallout: field.IsCallout,
            seqNo: field.SeqNo,
            isReadOnly: field.IsReadOnly,
            data: {
              isUpdateable: field.IsUpdateable,
              isAlwaysUpdatable: field.IsAlwaysUpdateable,
              isSameLine: field.IsSameLine,
              label: field.FieldGroup,
              isMandatory: field.IsMandatoryUI,
              DisplayLogic: field.FieldGroupDisplayLogic,
              isCollapsedDefault: field.FieldGroupExpanded !== undefined ? !field.FieldGroupExpanded : undefined
            },
            component: 'AccordionUiComponent',
            children: []
          };
          this.fieldGroups.push(fieldGroup);
          fieldGroup.children.push(fieldTransformed);
        }
        return fieldGroup;
      } else {
        return fieldTransformed;
      }
    }
    return undefined;
  }

  public static transformFieldForGrid(field: CompiereFieldInfo, isSearch: boolean = false): IupicsTableDataHeader {
    const canvas = document.createElement('canvas');
    document.body.appendChild(canvas);
    canvas.getContext('2d').font = 'normal normal bold 12px suisse_intlregular';
    const width = canvas.getContext('2d').measureText(field.Name ? field.Name : field.name).width + 64;
    document.body.removeChild(canvas);

    if ((field.IsDisplayed && field.MRSeqNo > 0) || isSearch) {
      const fieldTransformed: IupicsTableDataHeader = {
        field: field.ColumnName,
        headerName: field.Name ? field.Name : field.name,
        filter: 'agTextColumnFilter',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        editable: false,
        hide: !field.IsDisplayed,
        enableCellChangeFlash: false,
        width: width < 120 ? 120 : width,
        resizable: true,
        filterParams: {
          newRowsAction: 'keep',
          applyButton: true,
          clearButton: true,
          filterOptions: ['contains', 'notContains', 'startsWith']
        },
        tooltipValueGetter:
          field.AD_Reference_ID === 20
            ? null
            : (params) => {
                return params.valueFormatted;
              }
      };

      this.defaultLanguage = this.connectorService.getIupicsDefaultLanguage();
      let lang = this.defaultLanguage.iso_code.replace('_', '-');
      if (lang === 'fr-FR') {
        lang = 'de-CH';
      }
      switch (field.AD_Reference_ID) {
        case 20:
          // InputSwitchUiComponent
          fieldTransformed.cellRenderer = (params: any) =>
          !isNil(params.value) ? `<input type='checkbox' disabled='disabled' ${params.value === 'Y' ? 'checked' : ''} />` : '';
          break;
        case 24:
          // Time
          fieldTransformed.cellRenderer = (params: any) => ( !isNil(params.value)  ? `${moment(params.value).format('LT')}` : '');
          break;
        case 12:
          // Montant
          fieldTransformed.cellRenderer = (params: any) =>
          !isNil(params.value) ? `${params.value.toLocaleString(lang)}` : '';
          break;
        case 37:
          // Montant
          fieldTransformed.cellRenderer = (params: any) =>
          !isNil(params.value) ? `${params.value.toLocaleString(lang)}` : '';
          break;
        case 11:
          // Integer
          fieldTransformed.cellRenderer = (params: any) =>
          !isNil(params.value) ? `${params.value.toLocaleString(lang)}` : '';
          break;
        case 22:
          // Float
          fieldTransformed.cellRenderer = (params: any) =>
          !isNil(params.value) ? `${params.value.toLocaleString(lang)}` : '';
          break;
        case 29:
          // Float
          fieldTransformed.cellRenderer = (params: any) =>
          !isNil(params.value) ? `${params.value.toLocaleString(lang)}` : '';
          break;
        default:
          break;
      }
      return fieldTransformed;
    }

    return undefined;
  }

  public static getFilterTypeFromReference(referenceId: number) {
    return referenceId === 15 || referenceId === 16 || referenceId === 24
      ? CompiereDataGridFilterType.DATE
      : (referenceId >= 17 && referenceId <= 19) ||
        referenceId === 30 ||
        referenceId === 42 ||
        referenceId === 27 ||
        referenceId === 31 ||
        referenceId === 25
      ? CompiereDataGridFilterType.SET
      : referenceId === 11 ||
        referenceId === 12 ||
        referenceId === 13 ||
        referenceId === 22 ||
        referenceId === 29 ||
        referenceId === 37
      ? CompiereDataGridFilterType.NUMBER
      : CompiereDataGridFilterType.TEXT;
  }

  public static formatCtxArea(ctxArea: any): any {
    for (const key of Object.keys(ctxArea)) {
      if (ctxArea[key] === true) {
        ctxArea[key] = 'Y';
      } else if (ctxArea[key] === false) {
        ctxArea[key] = 'N';
      }
    }
    return ctxArea;
  }
}
