import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { DataStoreName, DataStoreStatus } from '@compiere-ws/models/compiere-data-json';
import { PoService } from '@compiere-ws/services/po/po.service';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'iu-input-text-ui',
  templateUrl: './input-text-ui.component.html',
  styleUrls: ['./input-text-ui.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InputTextUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  constructor(
    public elementRef: ElementRef,
    public store: DataStoreService,
    protected connectorService: SecurityManagerService,
    public uiCreatorService: UICreatorService,
    public cmService: ContextMenuService,
    renderer: Renderer2,
    protected po: PoService,
    protected cacheService: CacheManagerService

  ) {
    super(elementRef, connectorService, cmService, store, uiCreatorService, renderer, po, cacheService);
  }
  @Input()
  columnName: string;
  @Input()
  placeHolder: string;
  @ViewChild('input', { static: true })
  inputRef: ElementRef<HTMLInputElement>;
  @ViewChild('opConflict', { static: true })
  opConflict: OverlayPanel;
  dataContainers: AbstractDataContainerCallout;

  @Output() fieldValueChange = new EventEmitter<any>();
  @Output() enterKey = new EventEmitter<any>();

  ngOnInit() {
    super.ngOnInit();
    if (this.cssClass !== undefined) {
      this.cssGrid = this.cssClass;
    }
    this.cssClass = ' ' + this.cssGrid;
    this.setFieldMandatory();
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    if (parentComp instanceof EditTabUiComponent) {
      this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
    }
    if (this.data && this.data.callouts && this.data.callouts.callouts) {
      this.data.callouts.callouts.forEach((callout) => {
        this.inputRef.nativeElement.addEventListener(callout.calloutevent, () => {
          const calloutFn = new Function('Field', 'Fields', 'DBSelect', callout.script);
          calloutFn(this, this.dataContainers, this.uiCreatorService);
        });
      });
    }
    this.checkFocus();
  }

  checkFocus(){
    if (this.data && this.data.IsDefaultFocus && !this.isReadOnly
      && this.editViewParent
      && this.editViewParent.editTabs
      && this.editViewParent.editTabs.length > 0
      && this.editViewParent.editTabs[0].dataStored
      && this.editViewParent.editTabs[0].dataStored.status === DataStoreStatus.NEWRECORD
      && (this.fieldValue === null || this.fieldValue === undefined)) {
      this.inputRef.nativeElement.focus();
    }
  }

  showConflictPanel(ev) {
    ev.target.getBoundingClientRect = function () {
      return { top: this.offsetTop, left: this.offsetLeft };
    };
    this.opConflict.toggle(ev);
  }
  changePassWord() {
    if (this.data && this.data.fieldType === 'password' && this.fieldValue === null) {
      if (this.dataStored && this.dataStored.key) {
        const oldStore = this.store.getStore(this.dataStored.key, DataStoreName.OLD);
        if (oldStore && oldStore.data) {
          if (oldStore.data[this.data.columnName]) {
            this.fieldValue = oldStore.data[this.data.columnName];
          }
        }
      }
    }
  }
  resetPassWord() {
    if (this.data && this.data.fieldType === 'password' && this.fieldValue) {
      if (this.dataStored && this.dataStored.key) {
        const oldStore = this.store.getStore(this.dataStored.key, DataStoreName.OLD);
        if (oldStore && oldStore.data) {
          if (oldStore.data[this.data.columnName] && oldStore.data[this.data.columnName] === this.fieldValue) {
            this.fieldValue = null;
          }
        }
      }
    }
  }
}
