import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { InputNumberUiComponent } from '@iupics-components/standard/fields/input-number-ui/input-number-ui.component';

@Component({
  selector: 'app-number-renderer',
  template: `
    <iu-input-number-ui
      #numberElt
      [fieldValue]="this.fieldValue"
      [cssClass]="'numberRenderer'"
      [isLabelDisplay]="false"
      [data]="this.templates"
      [isStandalone]="true"
      [hasBtn]="false"
      (fieldValueModified)="onFieldModified($event)"
      (enterKey)="onEnter($event)"
      (inputBlur)="onBlur($event)"
    ></iu-input-number-ui>
  `
})
export class NumberEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  @ViewChild('numberElt', { read: InputNumberUiComponent }) numberElt: InputNumberUiComponent;
  params;
  label: string;
  templates: any;
  fieldValue = null;

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.numberElt.focus();
    });
  }

  agInit(params: any): void {
    this.params = params;
    this.label = this.params.label || null;
    this.templates = this.params.templates;

    if (params.value !== undefined) {
      this.fieldValue = params.value;
    }
  }

  getValue(): any {
    return this.numberElt.fieldValue;
  }

  isPopup(): boolean {
    return false;
  }

  onFieldModified(value) {
    this.params.node.data[this.templates.columnName] = value;
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: value,
        rowData: this.params.node.data,
        columnName: this.params.colDef.field
        // ...something
      };
      this.params.onClick(params);
    }
  }

  onEnter(event: KeyboardEvent) {
    this.onFieldModified(this.numberElt.fieldValue);
  }

  onBlur(event: FocusEvent) {
    event.stopPropagation();
    this.onFieldModified(this.numberElt.fieldValue);
    this.params.api.stopEditing();
  }
}
