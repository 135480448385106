<p-toolbar class="toolbar open" [ngStyle]="{ display: hasUniversalFilter ? '' : 'none' }">
  <div class="more-action" *ngIf="showMoreAction">
    <button
      *ngIf="
        visibleButton &&
        IsInsertRecord !== undefined &&
        IsInsertRecord === true &&
        (isReadOnly === undefined || isReadOnly === false)
      "
      pButton
      tooltipPosition="right"
      showDelay="250"
      pTooltip="{{ tooltipLabels.add }}"
      type="button"
      icon="icon-ico-add-2"
      class="p-menu-bar-button"
      (click)="newEmitter.emit()"
      iuKeybinding
      iuKeybinding-code="menu-bar.new"
      [iuKeybinding-active]="isTabTopLevel && !isSplitView"
      [iuKeybinding-focusTab]="activeTabID"
      data-cy="button-newRecord"
    ></button>
    <button
      *ngIf="visibleButton && visibleButtonMultiSelection && IsDeleteable && (isReadOnly === undefined || isReadOnly === false)"
      pButton
      tooltipPosition="top"
      showDelay="250"
      pTooltip="{{ tooltipLabels.delete }}"
      type="button"
      icon="icon-ico-delete"
      class="p-menu-bar-button"
      (click)="removeEmitter.emit()"
      iuKeybinding
      [iuKeybinding-active]="isTabTopLevel && !isSplitView"
      iuKeybinding-code="menu-bar.delete"
      [iuKeybinding-focusTab]="activeTabID"
    ></button>
    <button
      *ngIf="visibleButton || isFromForm"
      pButton
      tooltipPosition="top"
      showDelay="250"
      pTooltip="{{ tooltipLabels.refresh }}"
      type="button"
      icon="icon-ico-refresh3x"
      (click)="refreshEmitter.emit()"
      class="p-menu-bar-button"
      iuKeybinding
      [iuKeybinding-active]="isTabTopLevel && !isSplitView"
      iuKeybinding-code="menu-bar.refresh"
      [iuKeybinding-focusTab]="activeTabID"
      data-cy="grid-btn-refresh"
    ></button>
    <button
      *ngIf="visibleButton"
      pButton
      tooltipPosition="top"
      showDelay="250"
      pTooltip="{{ tooltipLabels.export }}"
      type="button"
      icon="icon-ico-export-ok"
      (click)="openExportDataModalEmitter.emit($event)"
      class="p-menu-bar-button"
      iuKeybinding
      [iuKeybinding-active]="isTabTopLevel && !isSplitView"
      iuKeybinding-code="menu-bar.export"
      [iuKeybinding-focusTab]="activeTabID"
    ></button>
    <button
      pButton
      tooltipPosition="top"
      showDelay="250"
      pTooltip="{{ tooltipLabels.gridView }}"
      *ngIf="visibleButton && (isTabTopLevel || visibleTreeButton)"
      type="button"
      icon="icon-ico-table"
      (click)="swicthToGridView()"
      class="p-menu-bar-button"
      iuKeybinding
      [iuKeybinding-active]="isTabTopLevel && !isSplitView"
      iuKeybinding-code="menu-bar.grid-view-type"
      [iuKeybinding-focusTab]="activeTabID"
    ></button>
    <button
      pButton
      tooltipPosition="top"
      showDelay="250"
      pTooltip="{{ tooltipLabels.kanbanView }}"
      *ngIf="visibleButton && isTabTopLevel"
      type="button"
      icon="icon-ico-kanban"
      (click)="switchToKanbanView()"
      class="p-menu-bar-button"
      iuKeybinding
      [iuKeybinding-active]="isTabTopLevel && !isSplitView"
      iuKeybinding-code="menu-bar.kanban-view-type"
      [iuKeybinding-focusTab]="activeTabID"
    ></button>
    <button
      pButton
      tooltipPosition="top"
      showDelay="250"
      pTooltip="{{ tooltipLabels.calendarView }}"
      *ngIf="visibleButton && isTabTopLevel"
      type="button"
      icon="icon-ico-calendar"
      (click)="switchToCalendarView()"
      class="p-menu-bar-button"
      iuKeybinding
      [iuKeybinding-active]="isTabTopLevel && !isSplitView"
      iuKeybinding-code="menu-bar.calendar-view-type"
      [iuKeybinding-focusTab]="activeTabID"
    ></button>
    <button
      pButton
      tooltipPosition="top"
      showDelay="250"
      pTooltip="{{ tooltipLabels.chartView }}"
      *ngIf="visibleButton && isTabTopLevel"
      type="button"
      icon="icon-ico-graph-smart"
      (click)="switchToChartView()"
      class="p-menu-bar-button"
      iuKeybinding
      [iuKeybinding-active]="isTabTopLevel && !isSplitView"
      iuKeybinding-code="menu-bar.chart-view-type"
      [iuKeybinding-focusTab]="activeTabID"
    ></button>
    <button
      pButton
      tooltipPosition="top"
      showDelay="250"
      pTooltip="{{ tooltipLabels.treeView }}"
      *ngIf="visibleButton && visibleTreeButton"
      type="button"
      icon="icon-ico-tree"
      (click)="switchToTreeView()"
      class="p-menu-bar-button"
      iuKeybinding
      [iuKeybinding-active]="isTabTopLevel && !isSplitView"
      iuKeybinding-code="menu-bar.tree-view-type"
      [iuKeybinding-focusTab]="activeTabID"
    ></button>
  </div>
  <div class="p-toolbar-group-left">
    <button
      *ngIf="isMobile && isTabTopLevel"
      pButton
      data-cy="menuBar-showButtons-btn"
      tooltipPosition="top"
      showDelay="250"
      life="3"
      pTooltip="{{ 'menuBarDetails.showButtons' | translate }}"
      type="button"
      icon="icon-ico-menu-bars"
      class="p-menu-bar-button"
      [attr.data-cy-toggle-state]="showMoreAction ? 'opened' : 'closed'"
      (mouseup)="onShowMoreAction($event)"
    ></button>
    <ng-container *ngIf="!isMobile || !isTabTopLevel">
      <button
        *ngIf="
          visibleButton &&
          IsInsertRecord !== undefined &&
          IsInsertRecord === true &&
          (isReadOnly === undefined || isReadOnly === false)
        "
        pButton
        tooltipPosition="right"
        showDelay="250"
        pTooltip="{{ tooltipLabels.add }}"
        type="button"
        icon="icon-ico-add-2"
        class="p-menu-bar-button"
        (click)="newEmitter.emit()"
        iuKeybinding
        iuKeybinding-code="menu-bar.new"
        [iuKeybinding-active]="isTabTopLevel && !isSplitView"
        [iuKeybinding-focusTab]="activeTabID"
        data-cy="button-newRecord"
      ></button>
      <button
        *ngIf="visibleButton && visibleButtonMultiSelection && IsDeleteable && (isReadOnly === undefined || isReadOnly === false)"
        pButton
        tooltipPosition="top"
        showDelay="250"
        pTooltip="{{ tooltipLabels.delete }}"
        type="button"
        icon="icon-ico-delete"
        class="p-menu-bar-button"
        (click)="removeEmitter.emit()"
        iuKeybinding
        [iuKeybinding-active]="isTabTopLevel && !isSplitView"
        iuKeybinding-code="menu-bar.delete"
        [iuKeybinding-focusTab]="activeTabID"
      ></button>
      <button
        pButton
        tooltipPosition="top"
        showDelay="250"
        pTooltip="{{ tooltipLabels.refresh }}"
        type="button"
        icon="icon-ico-refresh3x"
        (click)="refreshEmitter.emit()"
        class="p-menu-bar-button"
        iuKeybinding
        [iuKeybinding-active]="isTabTopLevel && !isSplitView"
        iuKeybinding-code="menu-bar.refresh"
        [iuKeybinding-focusTab]="activeTabID"
        data-cy="grid-btn-refresh"
      ></button>
      <button
        *ngIf="visibleButton"
        pButton
        tooltipPosition="top"
        showDelay="250"
        pTooltip="{{ tooltipLabels.export }}"
        type="button"
        icon="icon-ico-export-ok"
        (click)="openExportDataModalEmitter.emit($event)"
        class="p-menu-bar-button"
        iuKeybinding
        [iuKeybinding-active]="isTabTopLevel && !isSplitView"
        iuKeybinding-code="menu-bar.export"
        [iuKeybinding-focusTab]="activeTabID"
      ></button
    ></ng-container>
  </div>

  <div class="p-toolbar-group-right">
    <iu-universal-filter-ui [isMobile]="isMobile" [gridView]="gridView" [tabId]="tabId"></iu-universal-filter-ui
    ><ng-container *ngIf="!isMobile || !isTabTopLevel">
      <button
        pButton
        tooltipPosition="top"
        showDelay="250"
        pTooltip="{{ tooltipLabels.gridView }}"
        *ngIf="visibleButton && (isTabTopLevel || visibleTreeButton)"
        type="button"
        icon="icon-ico-table"
        (click)="swicthToGridView()"
        class="p-menu-bar-button"
        iuKeybinding
        [iuKeybinding-active]="isTabTopLevel && !isSplitView"
        iuKeybinding-code="menu-bar.grid-view-type"
        [iuKeybinding-focusTab]="activeTabID"
      ></button>
      <button
        pButton
        tooltipPosition="top"
        showDelay="250"
        pTooltip="{{ tooltipLabels.kanbanView }}"
        *ngIf="visibleButton && isTabTopLevel"
        type="button"
        icon="icon-ico-kanban"
        (click)="switchToKanbanView()"
        class="p-menu-bar-button"
        iuKeybinding
        [iuKeybinding-active]="isTabTopLevel && !isSplitView"
        iuKeybinding-code="menu-bar.kanban-view-type"
        [iuKeybinding-focusTab]="activeTabID"
      ></button>
      <button
        pButton
        tooltipPosition="top"
        showDelay="250"
        pTooltip="{{ tooltipLabels.calendarView }}"
        *ngIf="visibleButton && isTabTopLevel"
        type="button"
        icon="icon-ico-calendar"
        (click)="switchToCalendarView()"
        class="p-menu-bar-button"
        iuKeybinding
        [iuKeybinding-active]="isTabTopLevel && !isSplitView"
        iuKeybinding-code="menu-bar.calendar-view-type"
        [iuKeybinding-focusTab]="activeTabID"
      ></button>
      <button
        pButton
        tooltipPosition="top"
        showDelay="250"
        pTooltip="{{ tooltipLabels.chartView }}"
        *ngIf="visibleButton && isTabTopLevel"
        type="button"
        icon="icon-ico-graph-smart"
        (click)="switchToChartView()"
        class="p-menu-bar-button"
        iuKeybinding
        [iuKeybinding-active]="isTabTopLevel && !isSplitView"
        iuKeybinding-code="menu-bar.chart-view-type"
        [iuKeybinding-focusTab]="activeTabID"
      ></button>
      <button
        pButton
        tooltipPosition="top"
        showDelay="250"
        pTooltip="{{ tooltipLabels.treeView }}"
        *ngIf="visibleButton && visibleTreeButton"
        type="button"
        icon="icon-ico-tree"
        (click)="switchToTreeView()"
        class="p-menu-bar-button"
        iuKeybinding
        [iuKeybinding-active]="isTabTopLevel && !isSplitView"
        iuKeybinding-code="menu-bar.tree-view-type"
        [iuKeybinding-focusTab]="activeTabID"
      ></button
    ></ng-container>
  </div>
</p-toolbar>
