<wd-menu-top-ui
  #menuTop
  [activeTab]="activeTab"
  (changeDisplay)="updateWorkspace($event)"
  (validateLogoutEmitter)="validateLogoutEmitter.emit()"
  (changeRoleEmitter)="restoreEnvironment($event)"
  (generateWindowEmitter)="generateWindow($event)"
  (closeWindowEmitter)="closeWindow($event)"
  (setActiveTabEmitter)="setActiveTab($event)"
  (openGroupTabEmitter)="openGroupTab($event)"
  (swapToDashboardEmitter)="swapToDashBoard()"
  [isDashboardVisible]="dashboardIsActive"
  (openTargetSearch)="openTargetSearch($event)"
></wd-menu-top-ui>

<div class="wd-workspace">
  <ng-template #vcrTabSplitView></ng-template>
</div>
<ng-container *ngIf="displayContextPanel">
  <iu-modal-ui
    title="{{ contextPanelInfo.title | translate }}"
    [hasCloseBtn]="true"
    (closeModalEmitter)="displayContextPanel = false"
    ><div class="contextContainer">
      <div class="copied-to-clipboard" [ngStyle]="{ display: isCopiedToClipBoard ? null : 'none' }">
        {{ 'workspace.contextPanel.copyToClipBoard' | translate }}
      </div>
      <p-tabView>
        <p-tabPanel style="min-height: 50em" header="{{ 'workspace.contextPanel.UserContextTitle' | translate }}">
          <div class="contextTabContainer">
            <div class="contextLineContainer">
              <ng-container *ngFor="let userContextLine of contextPanelInfo.userContext">
                <input
                  type="text"
                  #contextLine
                  (click)="contextLine.select()"
                  class="contextLine"
                  readonly="true"
                  value=" {{ userContextLine.key }} : {{ userContextLine.value }}"
                />
              </ng-container>
            </div>
            <div class="buttonContainerCopyToClipBoard">
              <button
                class="btn btn-default copyToClipBoard"
                (click)="copyToClipBoard($event, 'user')"
                [title]="'workspace.contextPanel.copyToClipBoard' | translate"
              >
                {{ 'workspace.contextPanel.copyToClipBoard' | translate }}
              </button>
            </div>
          </div>
        </p-tabPanel>
        <ng-container *ngFor="let windowContextLine of contextPanelInfo.windowContexts">
          <p-tabPanel
            style="min-height: 50em"
            header="{{ 'workspace.contextPanel.WindowContextTitle' | translate }} : {{ windowContextLine.windowName }}"
          >
            <div class="contextTabContainer">
              <div class="contextLineContainer">
                <ng-container *ngFor="let windowContextValue of windowContextLine.values">
                  <input
                    type="text"
                    #contextLine
                    (click)="contextLine.select()"
                    class="contextLine"
                    readonly="true"
                    value=" {{ windowContextValue.key }} : {{ windowContextValue.value }}"
                  />
                </ng-container>
              </div>
              <div class="buttonContainerCopyToClipBoard">
                <button class="btn btn-default copyToClipBoard" (click)="copyToClipBoard($event, windowContextLine.windowName)">
                  {{ 'workspace.contextPanel.copyToClipBoard' | translate }}
                </button>
              </div>
            </div>
          </p-tabPanel>
        </ng-container>
      </p-tabView>
    </div>
  </iu-modal-ui>
</ng-container>
 
<iu-banner
  *ngFor="let error of errorMessages; let i = index"
  [type]="error.type ? error.type : 'error'"
  [position]="'bottom'"
  [closeable]="true"
  (closeEmitter)="closeErrorMessage(i)"
  [hiddenMessage]="error?.stack"
  [delay]="bannerCloseDelay"
>
  <div [innerHTML]="error.name + ': ' + error.message"></div>
</iu-banner> 

