<!-- #region body -->
<p-progressBar
  *ngIf="isLoading"
  [mode]="'indeterminate'"
  [value]="0"
  [showValue]="false"
  [styleClass]="'loading-progressbar'"
></p-progressBar>

<div class="iu-export-data-footer">
  <div class="p-col-12 p-md-6">
    <iu-autocomplete-ui
      #printerNameField
      label="{{ 'profil.printerName' | translate }}"
      [isStandalone]="true"
      columnName="Profile-AD_Language"
      [fieldValue]="this.printerName"
      [data]="printerNames"
      [isContextMenuDisabled]="true"
    ></iu-autocomplete-ui>
  </div>
</div>
<div class="iu-export-data-footer-buttons p-col-12 apiz-p-grid">
  <a class="iu-btn btn-execute" (click)="print()">{{ 'print.exportData' | translate }}</a>
  <a class="iu-btn btn-cancel" (click)="cancelPrintDataEmitter.emit()">{{ 'print.cancel' | translate }}</a>
</div>
