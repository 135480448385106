import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DataStore } from '@compiere-ws/models/compiere-data-json';
import { DataKanban, KanbanGroup } from '@iupics-components/models/kanban-interface';
import { BladeUiComponent } from '@iupics-components/standard/layouts/blade-ui/blade-ui.component';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { IupicsColumnKanban, IupicsField } from '@iupics-manager/models/iupics-data';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { KanbanUtils } from '../utils/kanban.utils';

@Component({
  selector: 'iu-kanban-board-ui',
  templateUrl: './kanban-board-ui.component.html',
  styleUrls: ['./kanban-board-ui.component.scss']
})
export class KanbanBoardUiComponent extends AbstractDynamicComponent implements OnInit {
  @Input()
  draggedElement: any;
  @Input()
  tableName: string;

  @Output()
  draggedElementChange: EventEmitter<any> = new EventEmitter();

  @Input()
  columns_display_AD: IupicsColumnKanban[];
  @Input()
  kanbanImageColumn: string;

  @Input()
  kanbanGroup: KanbanGroup;
  @Input()
  isFlexDesign: boolean;

  @Output()
  clickEmitter = new EventEmitter<any>();
  @Output()
  changeGroupEmitter = new EventEmitter<any>();

  @ViewChild('boardList')
  boardListDOM: ElementRef;
  constructor(
    private store: DataStoreService,
    private config: AppConfig,
    private uiCreatorService: UICreatorService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    if (this.kanbanGroup.datas.length <= 0) {
      this.moreData(true);
    }
  }

  onClick(dataKanban: DataKanban) {
    this.clickEmitter.emit(dataKanban.data['Data_UUID']);
  }

  dragstart(event, element, dataKanban: DataKanban) {
    if (this.kanbanGroup.columnName) {
      this.draggedElement = element;
      this.draggedElement['dataStore'] = dataKanban;
      this.draggedElement['kanbanGroup'] = this.kanbanGroup;
      this.draggedElement['updateKanbanGroup'] = (kanbanGroup) => {
        this.kanbanGroup = kanbanGroup;
      };
      this.draggedElementChange.emit(this.draggedElement);
    }
  }

  changeGroup(event) {
    if (
      this.draggedElement &&
      this.kanbanGroup.columnName &&
      this.draggedElement['dataStore'] &&
      this.draggedElement['dataStore'].data &&
      (this.draggedElement['dataStore'].data[this.kanbanGroup.columnName['field']].id !== this.kanbanGroup.groupValue.id ||
        (this.kanbanGroup.groupValue.id === undefined &&
          this.draggedElement['dataStore'].data[this.kanbanGroup.columnName['field']] !== this.kanbanGroup.groupValue))
    ) {
      const dataModified = {};
      dataModified[this.kanbanGroup.columnName['field']] = this.kanbanGroup.groupValue;
      const dataStoreKey = this.store.generateDataStoreKey(
        (<BladeUiComponent>this.container).infoComponent.windowId,
        this.tabId,
        this.draggedElement['dataStore'].data['Data_UUID'],
        null
      );
      const previousGroup = this.draggedElement['dataStore'].data[this.kanbanGroup.columnName['field']];
      this.draggedElement['dataStore'].data[this.kanbanGroup.columnName['field']] = this.kanbanGroup.groupValue;
      this.transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      this.updateContainers(event.previousContainer, event.container);

      this.uiCreatorService.getTab(this.tabId).subscribe((tab) => {
        let field = null;
        if (tab) {
          field = this.findDataField(tab[0].editView.children);
        }
        const fieldData = field ? field.data : null;
        if (!fieldData || !this.isFieldReadOnly(fieldData, this.draggedElement['dataStore'])) {
          this.store.updateStoreWithoutFields(dataStoreKey, this.draggedElement['dataStore'], dataModified);
          this.subscriptions.push(
            this.store.saveWindowData([dataStoreKey]).subscribe(
              (res) => {
                if (!res) {
                  this.changeGroupEmitter.emit();
                }
              },
              (err) => {
                this.changeGroupEmitter.emit();
              }
            )
          );
        } else {
          const msg = this.translateService.instant('kanban.dropError');
          this.draggedElement['dataStore'].data[this.kanbanGroup.columnName['field']] = previousGroup;
          this.transferArrayItem(event.container.data, event.previousContainer.data, event.currentIndex, event.previousIndex);
          this.updateContainers(event.previousContainer, event.container);
          throw new Error(msg.replace('@propertyName@', this.kanbanGroup.columnName['displayName']));
        }
      });
    } else {
      if (event.previousContainer === event.container) {
        this.moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.updateContainers(event.container);
      }
    }
  }
  isFieldReadOnly(fieldData: any, dataStored: DataStore): boolean {
    if (
      dataStored.data.DocStatus !== undefined &&
      dataStored.data.DocStatus !== null &&
      (dataStored.data.DocStatus.id === 'CO' || dataStored.data.DocStatus.id === 'CL') &&
      fieldData.isAlwaysUpdatable !== undefined &&
      fieldData.isAlwaysUpdatable !== null &&
      fieldData.isAlwaysUpdatable === false
    ) {
      return true;
    }
    if (fieldData.ReadOnlyLogic) {
      return LogicEvaluator.evaluateLogic(dataStored.data, fieldData.ReadOnlyLogic);
    }
    return false;
  }

  moreData(init = false) {
    // Pour éviter d'incrémenter le startrow si c'est la première fois
    if (!init) {
      this.kanbanGroup.dataStoreRequest.compiereRequest.startRow = this.kanbanGroup.dataStoreRequest.compiereRequest.endRow;
      this.kanbanGroup.dataStoreRequest.compiereRequest.endRow =
        this.kanbanGroup.dataStoreRequest.compiereRequest.endRow +
        this.config.getConstant('GridTabInfinityScrollUiComponent#cacheBlockSize');
    }
    this.subscriptions.push(
      this.store.getDataGrid(this.kanbanGroup.dataStoreRequest).subscribe((response) => {
        if (response.data) {
          const kanbanDatas = <DataKanban[]>(
            KanbanUtils.transformDataForKanbanView(response.data, this.columns_display_AD, this.kanbanImageColumn)
          );
          const kanbanGroupsCopy = cloneDeep(this.kanbanGroup);
          kanbanGroupsCopy.datas = [...this.kanbanGroup.datas, ...kanbanDatas];
          kanbanGroupsCopy.isMoreData = response.lastRow <= -1;
          this.kanbanGroup = kanbanGroupsCopy;
        }
      })
    );
  }

  findDataField(fields: IupicsField[]) {
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (field.data.columnName === this.kanbanGroup.columnName['field']) {
        return field;
      } else if (field.children) {
        const value = this.findDataField(field.children);
        if (value) {
          return value;
        }
      }
    }
    return null;
  }

  onChildUpdate(event): void {}

  onSiblingUpdate(event: IupicsEvent) {}

  onRemoveComponent(event: IupicsEvent) {}
  /**
   * Moves an item one index in an array to another.
   * @param array Array in which to move the item.
   * @param fromIndex Starting index of the item.
   * @param toIndex Index to which the item should be moved.
   */
  moveItemInArray<T = any>(array: T[], fromIndex: number, toIndex: number): void {
    const from = this.clamp(fromIndex, array.length - 1);
    const to = this.clamp(toIndex, array.length - 1);

    if (from === to) {
      return;
    }

    const target = array[from];
    const delta = to < from ? -1 : 1;

    for (let i = from; i !== to; i += delta) {
      array[i] = array[i + delta];
    }

    array[to] = target;
    array = [...array];
  }

  /**
   * Moves an item from one array to another.
   * @param currentArray Array from which to transfer the item.
   * @param targetArray Array into which to put the item.
   * @param currentIndex Index of the item in its current array.
   * @param targetIndex Index at which to insert the item.
   */
  transferArrayItem<T = any>(currentArray: T[], targetArray: T[], currentIndex: number, targetIndex: number): void {
    const from = this.clamp(currentIndex, currentArray.length - 1);
    const to = this.clamp(targetIndex, targetArray.length);

    if (currentArray.length) {
      const pos = currentArray.splice(from, 1)[0];
      currentArray = [...currentArray];
      targetArray.splice(to, 0, pos);
      targetArray = [...targetArray];
    }
  }

  /** Clamps a number between zero and a maximum. */
  clamp(value: number, max: number): number {
    return Math.max(0, Math.min(max, value));
  }
  /* reinit kanbangroup to refresh view */
  updateContainers(draggingContainer, droppingContainer = null) {
    if (this.draggedElement && this.draggedElement['kanbanGroup']) {
      const kanbanGroupsCopy = cloneDeep(this.draggedElement['kanbanGroup']);
      kanbanGroupsCopy.datas = [...draggingContainer.data];
      this.draggedElement['updateKanbanGroup'](kanbanGroupsCopy);
    }
    if (this.kanbanGroup && droppingContainer) {
      const kanbanGroupsCopy = cloneDeep(this.kanbanGroup);
      kanbanGroupsCopy.datas = [...droppingContainer.data];
      this.kanbanGroup = kanbanGroupsCopy;
    }
  }
  onVirtualScroll(event) {
    if (this.kanbanGroup.isMoreData) {
      if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
        this.moreData();
      }
    }
  }
  trackByFn(index, item) {
    return item[this.tableName + '_ID'];
  }
}
