import { formatNumber } from '@angular/common';
import { AfterViewInit, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ChartType } from '@iupics-components/models/view-type.enum';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { Global } from '@iupics-manager/models/global-var';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '@web-desktop/controllers/theme.service';
import { IupicsWidget } from '@web-desktop/models/iupics-widget';
import { UIChart } from 'primeng/chart';

@Component({
  selector: 'iu-chart-widget',
  templateUrl: './chart-widget.component.html',
  styleUrls: ['./chart-widget.component.scss']
})
export class ChartWidgetComponent implements OnInit, AfterViewInit {
  @Input()
  widget: IupicsWidget;
  @ViewChild('chart', { read: UIChart })
  chart: UIChart;
  options: any;
  plugins: any;
  multiDataset = false;
  noData = false;
  dataToDisplay = null;
  tooltipLabels: any;
  lastLegendDisplayState: boolean;
  lang = 'fr-FR';
  constructor(
    private translateService: TranslateService,
    private themeService: ThemeService,
    private connectorService: SecurityManagerService
  ) {}

  updateChart(chartType: ChartType) {
    this.widget.chartType = chartType;
    this.dataToDisplay = this.widget.data;
    if (this.dataToDisplay.datasets.length === 0 || this.dataToDisplay.labels.length === 0) {
      this.noData = true;
    }
    if (!this.noData) {
      if ([ChartType.BAR, ChartType.RADAR].includes(this.widget.chartType)) {
        this.dataToDisplay.datasets.forEach((dataset) => (dataset.fill = true));
      } else {
        this.dataToDisplay.datasets.forEach((dataset) => {
          (dataset.fill = false);
          dataset.labels = this.dataToDisplay.labels;
        } );
      }
      if (this.widget.viewType === 'chart') {
        this.multiDataset =
          this.widget.chartType === ChartType.BAR ||
          this.widget.chartType === ChartType.RADAR ||
          this.widget.chartType === ChartType.HORIZONTAL_BAR ||
          this.widget.chartType === ChartType.LINE;

        const palette: any[] = this.themeService.getThemeProperty('colorsPalette');
        if (this.dataToDisplay.datasets.length > 1) {
          this.dataToDisplay.datasets.forEach((dataset: any, i: number) => {
            const color = i < palette.length ? palette[i] : this.getRandomColor();
            const _opacity = Math.round(Math.min(Math.max(0.7 || 1, 0), 1) * 255);
            const backgroundColor = color + _opacity.toString(16).toUpperCase();
            dataset.borderColor = color;
            dataset.pointBorderColor = '#fff';
            dataset.pointBackgroundColor = color;
            dataset.backgroundColor = backgroundColor;
            dataset.hoverBackgroundColor = color;
          });
        } else if (this.dataToDisplay.datasets.length > 0) {
          this.dataToDisplay.labels.forEach((label: string, i: number) => {
            const color = i < palette.length ? palette[i] : this.getRandomColor();
            const _opacity = Math.round(Math.min(Math.max(0.7 || 1, 0), 1) * 255);
            const backgroundColor = color + _opacity.toString(16).toUpperCase();
            this.dataToDisplay.datasets[0].backgroundColor.push(backgroundColor);
            this.dataToDisplay.datasets[0].hoverBackgroundColor.push(color);
            this.dataToDisplay.datasets[0].borderColor = '#fff';
            this.dataToDisplay.datasets[0].pointBorderColor = '#fff';
            this.dataToDisplay.datasets[0].pointBackgroundColor = color;
          });
        }

        this.options = {
          title: {
            display: true,
            fontSize: 12,
            text: this.widget.description,
            dockInsidePlotArea: false
          },
          tooltips: {
            callbacks: {
              title: (item, data) => {
                if ([ChartType.DOUGHNUT, ChartType.PIE, ChartType.POLARAREA].includes(this.widget.chartType)) {
                  const label = data.datasets[item[0].datasetIndex].labels[item[0].index];
                  return label;
                } else {
                  const label = data.datasets[item[0].datasetIndex].label;
                  return label;
                }
              },
              label: (item, data) => {
                let dataSetValue: any = null;
                dataSetValue = parseFloat(data.datasets[item.datasetIndex].data[item.index]);
                const total = data.datasets[item.datasetIndex].data.reduce((a, b) => {
                    return a + parseFloat(b);
                }, 0);
                const pourcentage = ((dataSetValue / total) * 100).toFixed(2) + '%';
                return formatNumber(dataSetValue, this.lang) + (total !== 0 ? ` (${pourcentage})` : '');
              }
            }
          },
          legend: {
            display: false
          },
          scales: {
          },
          lang : this.lang
        };
        if ([ChartType.BAR, ChartType.HORIZONTAL_BAR, ChartType.LINE].includes(this.widget.chartType)) {
            this.options.scales = {
            yAxes: [
                {
                    ticks: {
                        callback: function(label, index, labels) {
                          const dataSetValue = parseFloat(label);
                          return formatNumber(dataSetValue, this.chart.options.lang);
                        }
                    }
                }
              ]
          };
        }
      }
      setTimeout(() => this.chart.reinit(), 0);
    }
  }

  ngOnInit() {
    this.lang = this.connectorService.getIupicsDefaultLanguage().iso_code.replace('_', '-');
    if(this.lang === 'fr-FR') {
      this.lang = 'de-CH';
    }
    this.lastLegendDisplayState = false;
    this.updateChart(this.widget.chartType);
    this.tooltipLabels = {
      pieChart: this.translateService.instant('chartWidgetUi.pieChart'),
      doughnutChart: this.translateService.instant('chartWidgetUi.doughnutChart'),
      polarChart: this.translateService.instant('chartWidgetUi.polarChart'),
      lineChart: this.translateService.instant('chartWidgetUi.lineChart'),
      barChart: this.translateService.instant('chartWidgetUi.barChart'),
      horizontalBarChart: this.translateService.instant('chartWidgetUi.horizontalBarChart'),
      radarChart: this.translateService.instant('chartWidgetUi.radarChart'),
      showLegend: this.translateService.instant('chartWidgetUi.showLegend')
    };
  }

  ngAfterViewInit() {
    // check si on est sur mobile ou si le widget est trop petit pour afficher la légende
    if (!this.noData) {
      this.adaptOptionsWithScreen();
    }
  }

  private getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  swicthToPieChart() {
    this.updateChart(ChartType.PIE);
  }

  swicthToLineChart() {
    this.updateChart(ChartType.LINE);
  }

  swicthToPolarChart() {
    this.updateChart(ChartType.POLARAREA);
  }

  swicthToBarChart() {
    this.updateChart(ChartType.BAR);
  }

  swicthToDoughnutChart() {
    this.updateChart(ChartType.DOUGHNUT);
  }

  swicthToRadarChart() {
    this.updateChart(ChartType.RADAR);
  }
  // Permet d'afficher ou cacher la légende
  changeLegendView() {
    this.options.legend.display = !this.options.legend.display;
    this.lastLegendDisplayState = !this.lastLegendDisplayState;
    this.chart.reinit();
  }
  /**
   * Met le boolean a true ou false selon la largeur de l'écran
   * gère la présence de la légende quand le widget est trop petit ou sur mobile
   * @param {Event}event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    event.stopPropagation();
    if (this.chart) {
      this.adaptOptionsWithScreen();
    }
  }

  adaptOptionsWithScreen() {
    if (this.chart.el.nativeElement.children[0].clientWidth <= 400 || Global.getDeviceWidth() <= 640) {
      this.options.title.fontSize = 11;
      this.options.title.dockInsidePlotArea = true;
      this.options.legend.position = 'right';
    } else {
      this.options.title.fontSize = 12;
      this.options.title.dockInsidePlotArea = false;
      this.options.legend.position = 'top';
    }
    if (this.lastLegendDisplayState !== this.options.legend.display) {
      this.lastLegendDisplayState = !this.lastLegendDisplayState;
      this.chart.reinit();
    }
  }
}
