<span
  #spanRef
  [style.display]="displayCss"
  (contextmenu)="onContextMenu($event)"
  (press)="onContextMenu($event)"
  [style.backgroundColor]="backgroundColorCss"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
>
  <iu-prime-autocomplete
    (completeMethod)="search($event)"
    (mousedown)="onMouseDown($event)"
    [isLabelDisplay]="this.isLabelDisplay"
    (onFocus)="search($event)"
    (click)="onInputClick($event)"
    (onBlur)="blurEmitter.emit($event)"
    (onSelect)="dataChange(autoComplete.value)"
    (onUnselect)="dataChange(autoComplete.value)"
    (selectItemEmitter)="calloutEventChange()"
    (showFormPanelEmitter)="showFormPanel($event)"
    (showSearchPanelEmitter)="showSearchPanel()"
    [(suggestions)]="suggestions"
    [data]="data"
    [columnName]="columnName"
    [dataStored]="dataStored"
    [description]="tooltip"
    [readonly]="isReadOnly"
    [disabled]="isReadOnly"
    [dropdown]="true"
    [iconClass]="iconClass"
    [label]="label"
    [multiple]="multiple"
    [ngClass]="[hasConflict ? 'iu-field-conflict' : '']"
    [scrollHeight]="scrollHeight"
    [suggestionsFilter]="suggestionsFilter"
    [tabID]="data?.tabId"
    [isRendererView]="isRendererView"
    [isInsideOverflow]="isInsideOverflow"
    [overridedCSS]="overridedCSS"
    #autoComplete
  ></iu-prime-autocomplete>
  <i
    *ngIf="hasConflict && dataStored"
    #spanInput
    class="ui-inputgroup-addon rtconflict fas fa-exclamation-triangle iconClass iu-field-conflict-icon"
    (click)="showConflictPanel($event)"
  ></i>
  <p-overlayPanel #opValuePref *ngIf="showValuePrefPanel"  [baseZIndex]="6000" [dismissable]="true" appendTo="body">
    <iu-value-preference-panel 
      [overlayPanel]="opValuePref"
      [fieldValue]="fieldValue"
      [dataStored]="this.dataStored"
      [sourceComponent]="this"
      [data]="this.data"
      (closeEmitter)="toggleValuePreferencePanel($event)"
    ></iu-value-preference-panel>
  </p-overlayPanel>
</span>

<p-overlayPanel #opConflict>
  <a class="data-conflict" (click)="chooseData($event, (dataStored?.data)[data.columnName], true); opConflict.hide($event)">{{
    (dataStored?.data)[data.columnName]?.displayValue
  }}</a>
  |
  <a class="data-conflict" (click)="chooseData($event, conflictedData, false); opConflict.hide($event)">{{
    conflictedData?.displayValue
  }}</a>
</p-overlayPanel>
