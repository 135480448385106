// REMOVE AFTER RELEASE 2.8.0
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CompiereNotification, CompiereNotificationPriority } from '@compiere-ws/models/compiere-notification-json';
import { PushNotificationsService } from '@compiere-ws/services/push-notifications/push-notifications.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { FilterListOperator, FilterListOptions } from '@iupics-components/pipes/filter-list/filter-list.pipe';
import { AppConfig } from '@iupics-config/app.config';
import { NotificationManagerService } from '@iupics-manager/managers/notification-manager/notification-manager.service';
import { Global } from '@iupics-manager/models/global-var';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
@Component({
  selector: 'wd-notification-center-ui',
  templateUrl: './notification-center-ui.component.html',
  styleUrls: ['./notification-center-ui.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationCenterUiComponent implements OnInit, OnDestroy {
  private datas: CompiereNotification[] = [];
  private _datas$: Subject<CompiereNotification[]>;
  datas$: Observable<CompiereNotification[]>;
  areButtonsDisabled = false;
  messageInfoDialog: string;
  savedDate: number;
  subscriptions: any[] = [];
  viewAllToolTip: string;
  deleteAllToolTip: string;
  isInfoDialogShow = false;
  @Output()
  updateNbNotification: EventEmitter<any> = new EventEmitter();
  @Output()
  toggleNotifCenter: EventEmitter<any> = new EventEmitter();
  timerList: Map<number, any> = new Map<number, any>();
  filterListOptions: FilterListOptions;
  constructor(
    private socketService: SocketService,
    private notificationManager: NotificationManagerService,
    private pushNotificationService: PushNotificationsService,
    private config: AppConfig
  ) {
    this.pushNotificationService.requestPermission();
  }

  ngOnInit() {
    this.socketService.initSocket();
    this.subscription();
    this.updateNbNotif();
    this.sortNotif();
    Global.notificationCenter = this;
    this._datas$ = new Subject();
    this.datas$ = this._datas$.asObservable();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });

    this.socketService.disableBroadcastNotifications();
    this.socketService.disablePersonalNotifications();
    this.socketService.disableRoleNotifications();
  }

  /**
   * Initialise les subscriptions
   */
  subscription() {
    (async () => {
      for await (const notification of this.socketService.enableBroadcastNotifications()) {
        this.newNotif(notification);
        this.popNotifications(notification);
      }
    })();

    (async () => {
      for await (const notification of this.socketService.enablePersonalNotifications()) {
        this.newNotif(notification);
        this.popNotifications(notification);
      }
    })();

    (async () => {
      for await (const notification of this.socketService.enableRoleNotifications()) {
        this.newNotif(notification);
        this.popNotifications(notification);
      }
    })();

    const sub = this.notificationManager.getNotifications().subscribe((notifications) => {
      this.datas = [];
      let shouldOpen = false;
      if (notifications && notifications.length > 0) {
        notifications.reverse().forEach((n) => {
          this.newNotif(n);
          if (n.closed === false && n.priority === CompiereNotificationPriority.IMPORTANT) {
            shouldOpen = true;
          }
        });
      } else {
        this.newNotif();
      }
      if (shouldOpen) {
        this.toggleNotifCenter.emit(null);
      }
      sub.unsubscribe();
    });
    this.subscriptions.push(
      this.notificationManager.onChangeRoleChannel.subscribe(({ previousRole, nextRole }) => {
        this.socketService.disableRoleNotifications();
        (async () => {
          for await (const notification of this.socketService.enableRoleNotifications(nextRole)) {
            this.newNotif(notification);
            this.popNotifications(notification);
          }
        })();
      }),
      this.notificationManager.onRoleChanged.subscribe(() => {
        const s = this.notificationManager.getNotifications().subscribe((notifications: CompiereNotification[]) => {
          this.datas = [];
          if (notifications && notifications.length > 0) {
            notifications.reverse().forEach((n) => {
              this.newNotif(n);
            });
          } else {
            this.newNotif();
          }
          s.unsubscribe();
        });
      })
    );
  }

  /**
   * Ajoute une notification
   * @param {CompiereNotification}notif
   */
  newNotif(notif?: CompiereNotification) {
    if (notif !== undefined) {
      if (notif.closed === false && notif.priority === CompiereNotificationPriority.IMPORTANT) {
        this.startTimer(notif);
      }
      this.datas.unshift(notif);
    }
    this.updateNbNotif();
    this.sortNotif();
  }
  /**
   * trie les notifs sur la date de création, de lecture et la priorité
   */
  sortNotif() {
    this.datas
      .sort((a, b) => {
        return moment(a.created).isSame(moment(b.created)) ? 0 : moment(a.created).isBefore(moment(b.created)) ? 1 : -1;
      })
      .sort((a, b) => {
        const weightA = this.getWeightPriority(a.priority);
        const weightB = this.getWeightPriority(b.priority);
        return weightB - weightA;
      })
      .sort((a, b) => {
        const weightA = a.closed ? 0 : 1;
        const weightB = b.closed ? 0 : 1;
        return weightB - weightA;
      });
    if (this._datas$) {
      this._datas$.next(this.datas);
    }
  }
  /**
   * Donne le poid d'une priorité
   * @param {CompiereNotificationPriority}priority
   */
  getWeightPriority(priority: CompiereNotificationPriority) {
    switch (priority) {
      case CompiereNotificationPriority.IMPORTANT:
        return 3;
      case CompiereNotificationPriority.HIGH:
        return 2;
      case CompiereNotificationPriority.MEDIUM:
        return 1;
      case CompiereNotificationPriority.LOW:
        return 0;
      default:
        return 0;
    }
  }
  /**
   * Supprime une notification
   * @param {CompiereNotification}item
   */
  deleteNotification(item: CompiereNotification): void {
    this.areButtonsDisabled = true;
    const index = this.datas.findIndex((data) => data.request_id === item.request_id);
    this.datas.splice(index, 1);
    this.updateNbNotif();
    this.clearTimer(item.request_id);
    this.areButtonsDisabled = false;
    this.subscriptions.push(this.notificationManager.closeNotification(item).subscribe());
  }

  /**
   * Emet un event pour mettre à jour le nombre de notification dans le menu-top et dans le title
   */
  updateNbNotif(notif: CompiereNotification = null) {
    if (notif) {
      this.readNotif(notif.request_id);
    }
    this.updateNbNotification.emit(this.datas.filter((data) => data.closed === false).length);
  }

  /**
   * permet d'enclencher un timer pour faire un rappel
   * @param {CompiereNotification}notif
   */
  startTimer(notif: CompiereNotification) {
    const defaultTimeOut = this.config.getConstant('notificationReminderTimeout');
    const timeout = defaultTimeOut ? defaultTimeOut : 2;
    const intervalID = setInterval(() => {
      this.popNotifications(notif);
    }, timeout * 60000);
    this.timerList.set(notif.request_id, intervalID);
  }
  /**
   * permet d'annuler le timer
   * @param {Number}request_id
   */
  clearTimer(request_id: number) {
    if (this.timerList.get(request_id)) {
      clearInterval(this.timerList.get(request_id));
      this.timerList.delete(request_id);
    }
  }
  /**
   * permet d'annuler tous les timers existants
   * @param {Number}request_id
   */
  clearAllTimer() {
    this.timerList.forEach((id, requestId) => {
      this.clearTimer(requestId);
    });
  }
  /**
   * passe le statut de la notif en lue
   * @param {Number}request_id
   */
  readNotif(request_id: number) {
    const index = this.datas.findIndex((data) => data.request_id === request_id);
    this.datas[index].closed = true;
    this.clearTimer(request_id);
  }
  /**
   * Change le status d'une notification
   * @param {MouseEvent}event
   * @param {CompiereNotification}item
   */
  handleNotification(event: MouseEvent, item: CompiereNotification): void {
    event.stopPropagation();
    if (item.closed === false) {
      this.subscriptions.push(
        this.notificationManager.handleNotification(item).subscribe(() => {
          this.readNotif(item.request_id);
          this.updateNbNotif();
        })
      );
    }
  }

  /**
   * Fait apparaitre les notifications dans une pop-up
   * @param {CompiereNotification[]}notifications
   */
  popNotifications(...notifications: CompiereNotification[]): any {
    if (this.pushNotificationService.permission === 'granted') {
      this.pushNotificationService.generateNotification(notifications);
    }
    this.notificationManager.popNotification(...notifications);
  }

  /**
   * Télécharge les fichiers passés en paramètre
   * @param {CompiereNotification}item
   * @param { string | Array }fileLinks
   */
  handleDlFile(fileLinks: any, item: CompiereNotification) {
    if (typeof fileLinks === 'string') {
      fileLinks.split(',').forEach((fileLink) => {
        this.notificationManager.downloadReport(fileLink);
      });
    } else {
      fileLinks.forEach((fileLink) => {
        this.notificationManager.downloadReport(fileLink.path);
      });
    }
    this.handleNotification(document.createEvent('MouseEvent'), item);
  }

  deleteAllNotifications(event: MouseEvent) {
    event.stopPropagation();
    this.subscriptions.push(
      this.notificationManager.closeAllNotification().subscribe(() => {
        this.datas.splice(0, this.datas.length);
        this.clearAllTimer();
        this.updateNbNotif();
        this.sortNotif();
        this.messageInfoDialog = '';
        this.isInfoDialogShow = false;
      })
    );
  }

  showDeleteDialog(event: MouseEvent) {
    event.stopPropagation();
    this.messageInfoDialog = 'Voulez-vous vider la liste?';
    this.isInfoDialogShow = true;
  }

  viewAllNotifications(event: MouseEvent) {
    this.notificationManager.handleAllNotification().subscribe(() => {
      this.datas.map((data) => (data.closed = true));
      this.clearAllTimer();
      this.updateNbNotif();
      this.sortNotif();
    });
  }

  cancelInfoDialog(event: MouseEvent) {
    event.stopPropagation();
    this.messageInfoDialog = '';
    this.isInfoDialogShow = false;
  }

  refresh(event: MouseEvent) {
    event.stopPropagation();
    this.clearAllTimer();
    const sub = this.notificationManager.getNotifications().subscribe((notifications) => {
      this.datas = [];
      if (notifications && notifications.length > 0) {
        notifications.reverse().forEach((n) => {
          this.newNotif(n);
        });
      } else {
        this.newNotif();
      }
      sub.unsubscribe();
    });
  }
  updateFilterOptions(event: any, inputValue: string) {
    this.filterListOptions = { objProperties: ['title'], operator: FilterListOperator.CONTAINS, value: inputValue };
  }
}
