import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { DataStore } from '@compiere-ws/models/compiere-data-json';
import { CompiereField } from '@compiere-ws/models/window-json';
import { PoService } from '@compiere-ws/services/po/po.service';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { UICreatorUtils } from '@iupics-manager/managers/ui-creator/utils/ui-creator.utils';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsField } from '@iupics-manager/models/iupics-data';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { TranslateService } from '@ngx-translate/core';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import * as moment from 'moment';
import { CalendarUiComponent } from '../calendar-ui/calendar-ui.component';
import { InputTextUiComponent } from '../input-text-ui/input-text-ui.component';

@Component({
  selector: 'iu-input-product-attributes-ui',
  templateUrl: './input-product-attributes-ui.component.html',
  styleUrls: ['./input-product-attributes-ui.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputProductAttributesUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  @ViewChild(DynamicContainerDirective, { read: ViewContainerRef })
  set createdVCR(vcr: ViewContainerRef) {
    if (vcr) {
      this.createFieldDataStore();
      this.vcr = vcr;
      this.constructContainer();
    }
  }

  @Input()
  attributeInstance_id = 0;
  @ViewChild('input', { static: true })
  inputRef: ElementRef;
  dataContainers: AbstractDataContainerCallout;
  displayPanel = false;
  displaySuggestion = false;
  suggestions = [];
  suggestionSelected: any;
  fieldDataStore: DataStore;
  specificDisplaySearch = false;
  private baseAttributeSetID: number;

  constructor(
    public elementRef: ElementRef,
    public store: DataStoreService,
    private windowFactory: WindowFactoryService,
    protected connectorService: SecurityManagerService,
    public uiCreatorService: UICreatorService,
    public cmService: ContextMenuService,
    renderer: Renderer2,
    public messageManager: MessageManagerService,
    private translateService: TranslateService,
    protected po: PoService,
    protected cacheService: CacheManagerService

  ) {
    super(elementRef, connectorService, cmService, store, uiCreatorService, renderer, po, cacheService);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.cssClass !== undefined) {
      this.cssGrid = this.cssClass;
    }
    this.cssClass = ' ' + this.cssGrid;
    this.setFieldMandatory();
    this.baseAttributeSetID = this.dataStored.data['M_AttributeSet_ID']
      ? this.dataStored.data['M_AttributeSet_ID'] instanceof Object
        ? this.dataStored.data['M_AttributeSet_ID'].id
        : this.dataStored.data['M_AttributeSet_ID']
      : 0;

    this.createFieldDataStore();
    this.suggestions = [];
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    if (parentComp instanceof EditTabUiComponent) {
      this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
    }
    if (this.data && this.data.callouts && this.data.callouts.callouts) {
      this.data.callouts.callouts.forEach((callout) => {
        this.inputRef.nativeElement.addEventListener(callout.calloutevent, () => {
          const calloutFn = new Function('Field', 'Fields', 'DBSelect', callout.script);
          calloutFn(this, this.dataContainers, this.uiCreatorService);
        });
      });
    }
  }

  changeDisplayPanel() {
    this.displayPanel = !this.displayPanel;
    // this.checkFieldValue();
  }

  constructContainer() {
    const product_id =
      this.dataStored.data['M_Product_ID'] instanceof Object
        ? this.dataStored.data['M_Product_ID'].id
        : this.dataStored.data['M_Product_ID'];
    const warehouse_id =
      this.dataStored.data['M_Warehouse_ID'] instanceof Object
        ? this.dataStored.data['M_Warehouse_ID'].id
        : this.dataStored.data['M_Warehouse_ID'];
    let attributeInstanceId = this.dataStored.data['M_AttributeSetInstance_ID']
      ? this.dataStored.data['M_AttributeSetInstance_ID'] instanceof Object
        ? this.dataStored.data['M_AttributeSetInstance_ID'].id
        : this.dataStored.data['M_AttributeSetInstance_ID']
      : this.attributeInstance_id;
    // * utilisé pour vérifier si on a changé de collection d'attribut
    const currentAttributeSetID = this.dataStored.data['M_AttributeSet_ID']
      ? this.dataStored.data['M_AttributeSet_ID'] instanceof Object
        ? this.dataStored.data['M_AttributeSet_ID'].id
        : this.dataStored.data['M_AttributeSet_ID']
      : undefined;
    if (this.baseAttributeSetID !== currentAttributeSetID) {
      attributeInstanceId = this.attributeInstance_id;
    }

    const item: DynamicComponent = {
      container: this.container,
      DOMParentComponent: this,
      component: 'GridUiComponent',
      cssClass: 'p-col-12',
      productAttributeId:
        this.data.tabId === 180 && this.dataStored.data['M_AttributeSet_ID'] !== null
          ? this.dataStored.data['M_AttributeSet_ID'].id + '_' + this.data.tabId + '_' + product_id
          : ''
    };
    const observ = this.uiCreatorService.getProductAttribute(attributeInstanceId, product_id, this.data.tabId);
    this.subscriptions.push(
      observ.subscribe(
        (fieldsWS) => {
          const fields: IupicsField[] = [];
          fieldsWS.forEach((field) => {
            if (field.ColumnName !== 'NewEdit') {
              let details;
              if (field.ColumnName === 'SelectExisting') {
                this.data.details.keyColumn = 's.M_Product_ID';
                field.validationCode = `s.M_Product_ID = ${product_id} AND l.M_Warehouse_ID = ${warehouse_id}`;
                details = this.data.details;
              }
              field.AD_Tab_ID = this.data.tabId;
              // Remove this when WS is OK
              const compiereField: CompiereField = {
                field: field,
                details: details ? details : null,
                data: null,
                urlList: null,
                urlCallouts: null,
                urlSearch: field['selectClause'] ? field['selectClause'] : null // Pour gérer le bouton qui ouvre un search panel
              };
              // compiereField.field.AD_Reference_ID = 10; // Remove this when WS is OK
              if (field.listBoxVO && field.listBoxVO.options) {
                // compiereField.field.AD_Reference_ID = 30; // Remove this when WS is OK
                compiereField.data = [];
                field.listBoxVO.options.forEach((listValue) => {
                  compiereField.data.push({ id: listValue.id, displayValue: listValue.name });
                });
              }
              const fieldTransformed = UICreatorUtils.transformField(compiereField);
              if (fieldTransformed) {
                fields.push(fieldTransformed);
              }
            }
          });
          Global.productAttributes_caching.set(
            this.data.tabId === 180 && this.dataStored.data['M_AttributeSet_ID'] !== null
              ? this.dataStored.data['M_AttributeSet_ID'].id + '_' + this.data.tabId + '_' + product_id
              : '',
            fields
          );
          this.vcr.clear();

          this.windowFactory.newEventHandler({
            type: IupicsTypeEvent.showProductAttributeView,
            item: item
          });
          // this.dataStored.dataChange.subscribe(value => console.log(value));
        },

        (error) => {
          this.messageManager.newMessage({
            name: `${error.name} - ${error.status}`,
            message: error.error.message,
            stack: `${error.message}\n${error.url}`
          });
          this.changeDisplayPanel();
        }
      )
    );
  }

  validAttributes() {
    const jsonToSave = {};
    const errors = [];
    if (
      this.DOMChildrenComponent &&
      this.DOMChildrenComponent.length > 0 &&
      this.DOMChildrenComponent[0].DOMChildrenComponent &&
      this.DOMChildrenComponent[0].DOMChildrenComponent.length > 0 &&
      this.DOMChildrenComponent[0].DOMChildrenComponent[0].DOMChildrenComponent &&
      this.DOMChildrenComponent[0].DOMChildrenComponent[0].DOMChildrenComponent.length > 0
    ) {
      this.DOMChildrenComponent[0].DOMChildrenComponent[0].DOMChildrenComponent.forEach((el) => {
        if (el instanceof CalendarUiComponent) {
          if (el.calendar.inputFieldValue !== null && el.calendar.inputFieldValue !== undefined && el.fieldValue !== '') {
            jsonToSave[el.data.columnName] = '' + moment(el.calendar.inputFieldValue, 'DD/MM/YYYY').toDate().getTime();
          } else {
            if (el.data.isMandatory) {
              errors.push(el.data.label);
            }
          }
        } else {
          if (el.fieldValue !== null && el.fieldValue !== undefined && el.fieldValue !== '') {
            jsonToSave[el.data.columnName] = el.fieldValue instanceof Object ? el.fieldValue.id : el.fieldValue + '';
          } else {
            if (el.data.isMandatory) {
              errors.push(el.data.label);
            }
          }
        }
      });
      if (errors.length === 0) {
        const product_id =
          this.dataStored.data['M_Product_ID'] instanceof Object
            ? this.dataStored.data['M_Product_ID'].id
            : this.dataStored.data['M_Product_ID'];
        const attributeInstanceId = this.dataStored.data['M_AttributeSetInstance_ID']
          ? this.dataStored.data['M_AttributeSetInstance_ID'] instanceof Object
            ? this.dataStored.data['M_AttributeSetInstance_ID'].id
            : this.dataStored.data['M_AttributeSetInstance_ID']
          : this.attributeInstance_id;
        const observ = this.uiCreatorService.saveProductAttribute(
          attributeInstanceId,
          product_id,
          this.data.tabId,
          this.data.fieldId,
          jsonToSave
        );
        this.subscriptions.push(
          observ.subscribe((result) => {
            if (result) {
              this.suggestionSelected = result[0];

              this.suggestions = [
                {
                  displayValue: '',
                  id: null
                },
                {
                  displayValue: result[0].displayValue,
                  id: result[0].id
                }
              ];

              this.dataStored.data['M_AttributeSetInstance_ID'] = result[0];
              this.setNewData(this.dataStored);
            }
          })
        );

        // this.fieldValue = '';
        this.displayPanel = !this.displayPanel;
        this.DOMChildrenComponent = [];
      } else {
        this.messageManager.newMessage(
          new IupicsMessage(this.translateService.instant('specificWindow.fillMandatory'), errors.join(', '), 'error')
        );
      }
    }
  }

  // checkFieldValue() {
  //   if (this.displayPanel && this.fieldValue) {
  //     setTimeout(() => {
  //       console.log(this.fieldValue);
  //       const arrayField = (<string>this.fieldValue.displayValue).split(',');
  //       const i = 0;
  //     }, 0);
  //   }
  // }

  // changeInput(value) {
  //   console.log(value);
  //   // this.fieldValue = value;
  // }

  setSearchSelectItem(event) {
    this.specificDisplaySearch = false;
    this.DOMChildrenComponent[0].DOMChildrenComponent[0].DOMChildrenComponent.forEach((el) => {
      if (el instanceof CalendarUiComponent) {
        const current_date = new Date(event.data['' + el.data.columnName]);
        el.calendar.inputFieldValue =
          current_date.getDate() + '/' + (current_date.getMonth() + 1) + '/' + current_date.getFullYear();
      } else if (el instanceof InputTextUiComponent) {
        el.fieldValue =
          el.data.columnName === 'Lot'
            ? (el.fieldValue = event.data['M_Lot_ID'])
            : (el.fieldValue = event.data['' + el.data.columnName]);
      }
    });
  }

  selectItem(item) {
    this.suggestionSelected = item;
    this.displaySuggestion = false;
    this.dataStored.data['M_AttributeSetInstance_ID'] = item;
    this.setNewData(this.dataStored);
  }

  showSuggestions() {
    this.displaySuggestion = true;
  }

  private createFieldDataStore() {
    this.fieldDataStore = new DataStore();
    this.fieldDataStore.key = { ...this.dataStored.key };
    this.fieldDataStore.key.recordId = `M_AttributeSetInstance_ID${
      this.dataStored.data['M_AttributeSetInstance_ID']
        ? this.dataStored.data['M_AttributeSetInstance_ID'] instanceof Object
          ? this.dataStored.data['M_AttributeSetInstance_ID'].id
          : this.dataStored.data['M_AttributeSetInstance_ID']
        : 0
    }`;
  }
}
