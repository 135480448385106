<span
  [style.display]="displayCss"
  [pTooltip]="tooltip"
  tooltipZIndex="2100"
  life="3"
  tooltipPosition="top"
  showDelay="500"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
  (contextmenu)="onContextMenu($event)"
  data-cy="input-location"
  [attr.data-cy-columnName]="data ? data.columnName : columnName"
>
  <label *ngIf="this.isLabelDisplay">
    <span (click)="zoomAcross()" class="adressLabelZoom">{{
      label | textLimit: this.elementRef.nativeElement.firstElementChild.firstElementChild.clientWidth / 7
    }}</span></label
  >
  <input
    #input
    [ngModel]="fieldValue ? fieldValue.displayValue : ''"
    (ngModelChange)="fieldValue ? (fieldValue.displayValue = $event.target.value) : ''"
    [readonly]="isReadOnly ? 'disabled' : null"
    [tabindex]="isReadOnly ? '-1' : null"
    value=""
    type="text"
    (keydown)="blocInput()"
    pInputText
    [ngClass]="[hasConflict ? 'iu-input-text-conflict' : '', mandatoryCss || '', 'ui-corner-all']"
    autocomplete="no"
  />
  <i
    data-cy="button-location"
    class="iconeLocation fa-map-marker"
    (click)="!isReadOnly && displayLocationPanel()"
    aria-hidden="true"
    type="button"
    [attr.readonly]="isReadOnly ? 'disabled' : null"
  ></i>
  <span
    *ngIf="hasConflict && dataStored"
    #spanInput
    class="ui-inputgroup-addon p-autocomplete-conflict-star"
    (mouseenter)="opConflict.show($event)"
  >
    /!\
  </span>
  <p-overlayPanel #opValuePref *ngIf="showValuePrefPanel" [baseZIndex]="6000"  [dismissable]="true" appendTo="body">
    <iu-value-preference-panel
      [overlayPanel]="opValuePref"
      [fieldValue]="fieldValue"
      [dataStored]="this.dataStored"
      [sourceComponent]="this"
      [data]="this.data"
      (closeEmitter)="toggleValuePreferencePanel($event)"
    ></iu-value-preference-panel>
  </p-overlayPanel>
</span>

<p-overlayPanel #opConflict>
  <a class="data-conflict" (click)="chooseData($event, (dataStored?.data)[data.columnName], true); opConflict.hide($event)">{{
    (dataStored?.data)[data.columnName]
  }}</a>
  | <a class="data-conflict" (click)="chooseData($event, conflictedData, false); opConflict.hide($event)">{{ conflictedData }}</a>
</p-overlayPanel>
<div
  [hidden]="!displayLocation"
  class="locationPanel p-col-12"
  data-cy="location-panel"
  [attr.data-cy-columnName]="data ? data.columnName : columnName"
>
  <div class="locationPanel-content">
    <ng-template #vcrLocationPanel> </ng-template>
  </div>
</div>

<!-- <iu-location-panel
  *ngIf="displayLocation"
  [enableSize]="enableSize"
  [fieldValue]="fieldValue"
  [data]="data"
  [dataStored]="dataStored"
  (locationEmitter)="onLocationEmitter($event)"
></iu-location-panel> -->
