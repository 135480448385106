import {
  CompiereDataGridFilterModel,
  CompiereDataGridFilterType,
  CompiereDataGridGroupModel,
  CompiereDataGridRequestJSON,
  CompiereDataGridSortModelType,
  CompiereDataGridType
} from '@compiere-ws/models/compiere-data-json';
import { ColumnFilterAutocomplete, OperatorFilterAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { filterOperators, OperatorFilterType } from '@iupics-components/models/universal-filter';
import { CalendarConfig } from '@iupics-components/overrided/prime-calendar/prime-calendar.component';
import { AppConfig } from '@iupics-config/app.config';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';

export class UniversalFilterUtils {
  private static config: AppConfig;
  private static connectorService: SecurityManagerService;
  private static translator: TranslateService;

  public static setConfig(config: AppConfig) {
    this.config = config;
  }

  public static setConnectorService(connectorService: SecurityManagerService) {
    this.connectorService = connectorService;
  }

  public static setTranslateService(translator: TranslateService) {
    this.translator = translator;
  }

  /*
   * *****************************************************************
   * *****************************************************************
   * *****************************************************************
   * **************************** CHIPS ******************************
   * *****************************************************************
   * *****************************************************************
   * *****************************************************************
   */
  public static dataToDisplayToChips(dataToDisplay: DataToDisplay): FilterChip[] {
    const dataToTransform = this.cleanDataToDisplay(cloneDeep(dataToDisplay));
    moment.locale(this.connectorService.getIupicsDefaultLanguage().iso_code);
    const filterChips: FilterChip[] = [];
    dataToTransform.filters.forEach((f, index) => {
      let filterStr: string;
      let operatorStr: string;
      if (f.operator.operator) {
        if (f.operator.operator.isRange) {
          if (f.filter && f.filterTo) {
            filterStr =
              (f.operator.operator.filterType === CompiereDataGridFilterType.DATE && !f.configs?.calendarConfig?.todayMode
                ? moment(f.filter).format('YYYY-MM-DDTHH:mm:ss.SSS').substring(0, 10)
                : f.filter) +
              (this.translator ? this.translator.instant('universalFilter.and') : ' and ') +
              (f.operator.operator.filterType === CompiereDataGridFilterType.DATE && !f.configs?.calendarConfig?.todayMode
                ? moment(f.filterTo).format('YYYY-MM-DDTHH:mm:ss.SSS').substring(0, 10)
                : f.filterTo);
            operatorStr = f.operator.displayValue;
          } else if (f.filter && !f.filterTo) {
            filterStr =
              f.operator.operator.filterType === CompiereDataGridFilterType.DATE && !f.configs?.calendarConfig?.todayMode
                ? moment(f.filter).format('YYYY-MM-DDTHH:mm:ss.SSS').substring(0, 10)
                : f.filter;
            operatorStr = filterOperators.find(
              (op) => op.type === OperatorFilterType.BIGGER_EQUALS && op.filterType === f.column.columnInfo.filterType
            ).label;
          } else if (!f.filter && f.filterTo) {
            filterStr =
              f.operator.operator.filterType === CompiereDataGridFilterType.DATE && !f.configs?.calendarConfig?.todayMode
                ? moment(f.filterTo).format('YYYY-MM-DDTHH:mm:ss.SSS').substring(0, 10)
                : f.filterTo;
            operatorStr = filterOperators.find(
              (op) => op.type === OperatorFilterType.LESS_EQUALS && op.filterType === f.column.columnInfo.filterType
            ).label;
          }
        } else if (f.filter !== null && f.filter !== undefined) {
          if (Array.isArray(f.filter)) {
            filterStr = f.filter
              .map((item) => (item.displayValue !== undefined ? item.displayValue : item))
              .join(this.translator ? this.translator.instant('universalFilter.or') : ' or ');
            operatorStr = f.operator.displayValue;
          } else {
            filterStr =
              f.operator.operator.filterType === CompiereDataGridFilterType.DATE && !f.configs?.calendarConfig?.todayMode
                ? moment(f.filter).format('YYYY-MM-DDTHH:mm:ss.SSS').substring(0, 10)
                : f.filter.displayValue
                ? f.filter.displayValue
                : f.filter;
            operatorStr = f.operator.displayValue;
          }
        }
        if (filterStr !== null && filterStr !== undefined && operatorStr !== null && operatorStr !== undefined) {
          filterChips.push({
            icon: 'icon-filter',
            displayValue: `${f.column.displayValue} ${operatorStr} '${filterStr}'`,
            type: 'filters',
            index: index,
            columnName: f.column.id
          });
        }
      }
    });
    dataToTransform.groups.forEach((group, index) => {
      if (group && group.displayValue) {
        filterChips.push({
          icon: 'icon-group-check',
          displayValue: group.displayValue,
          type: 'groups',
          index: index
        });
      }
    });
    dataToTransform.sortings.forEach((sorting, index) => {
      if (sorting && sorting.column && sorting.column.displayValue) {
        filterChips.push({
          icon:
            sorting.sortingType === CompiereDataGridSortModelType.ASC
              ? 'icon-tri-az'
              : sorting.sortingType === CompiereDataGridSortModelType.DESC
              ? 'icon-tri-za'
              : 'icon-tri',
          displayValue: sorting.column.displayValue,
          type: 'sortings',
          index: index
        });
      }
    });
    return filterChips;
  }

  /*
   * *****************************************************************
   * *****************************************************************
   * *****************************************************************
   * ********************** DATA TO DISPLAY **************************
   * *****************************************************************
   * *****************************************************************
   * *****************************************************************
   */

  public static compiereDataGridToDataToDisplay(
    columnFilters: { items: ColumnFilterAutocomplete[] },
    operatorFilters: { items: OperatorFilterAutocomplete[] },
    filters: CompiereDataGridRequestJSON[]
  ): DataToDisplay[] {
    const dataToDisplay: DataToDisplay[] = [];
    filters.forEach((f) => {
      const data: DataToDisplay = {
        favorite: f.label,
        isDefault: f.isDefault,
        // groups: f.rowGroupCols.map(groupCol =>
        //   columnFilters.items.find(columnFilter => parseInt(groupCol.id, 10) === columnFilter.id)
        // ),
        groups: f.rowGroupCols.map((groupCol) => columnFilters.items.find((columnFilter) => groupCol.id === columnFilter.id)),
        sortings: f.sortModel.map((sortModel) => ({
          column: columnFilters.items.find(
            (columnName) => sortModel.colId === columnName.columnInfo.fieldEntity.field.ColumnName
          ),
          sortingType: sortModel.sort
        })),
        filters: [],
        notUFData: { pivotCols: f.pivotCols, valueCols: f.valueCols, pivotMode: f.pivotMode }
      };

      data.filters = this.filterModelToFilterToDisplay(columnFilters, operatorFilters, f);
      dataToDisplay.push(data);
    });
    return dataToDisplay;
  }

  public static filterModelToFilterToDisplay(
    columnFilters: { items: ColumnFilterAutocomplete[] },
    operatorFilters: { items: OperatorFilterAutocomplete[] },
    f: CompiereDataGridRequestJSON
  ): FilterToDisplay[] {
    const filterToDisplays: FilterToDisplay[] = [];
    if (f.filterModel) {
      Object.keys(f.filterModel).forEach((columnKey) => {
        const base: FilterToDisplay = {
          column: columnFilters.items.find(
            (columnFilter) =>
              columnFilter.columnInfo.fieldEntity.field.ColumnName === columnKey ||
              columnFilter.columnInfo.fieldEntity.field.ColumnSQL === columnKey
          ),
          filter: undefined,
          operator: undefined
        };
        const filterModel = f.filterModel[columnKey];
        if (
          filterModel.operators.length > 0 &&
          filterModel.values.length > 0 &&
          filterModel.operators.length === filterModel.values.length
        ) {
          for (let i = 0; i < filterModel.operators.length; i++) {
            const operator: OperatorFilterType = filterModel.operators[i];
            const filterToDisplay: FilterToDisplay = Object.assign({}, base);
            filterToDisplay.operator = operatorFilters.items.find(
              (operatorFilter) =>
                operatorFilter.operator.type === operator &&
                operatorFilter.operator.filterType === f.filterModel[columnKey].filterType
            );
            let values = filterModel.values[i];
            if (
              (filterModel.filterType === CompiereDataGridFilterType.NUMBER ||
                filterModel.filterType === CompiereDataGridFilterType.SET) &&
              !Array.isArray(values) &&
              (operator === OperatorFilterType.EQUALS || operator === OperatorFilterType.NOT_EQUALS)
            ) {
              values = [values + ''];
            }
            filterToDisplay.filter = values;
            if (filterModel.configs && filterModel.configs.length) {
              filterToDisplay.configs = filterModel.configs[i];
              if (
                filterToDisplay.configs &&
                filterToDisplay.configs.calendarConfig &&
                (filterToDisplay.configs.calendarConfig as CalendarConfig).todayMode
              ) {
                filterToDisplay.filter = this.getTranslatedTodayValue(filterToDisplay.configs.calendarConfig.todayValue);
              }
            }
            if (operator === 'between') {
              filterToDisplay.filterTo = filterModel.values[++i];
              if (filterModel.configs && filterModel.configs.length > i) {
                filterToDisplay.configsTo = filterModel.configs[i];
                if (
                  filterToDisplay.configsTo &&
                  filterToDisplay.configsTo.calendarConfig &&
                  (filterToDisplay.configsTo.calendarConfig as CalendarConfig).todayMode
                ) {
                  filterToDisplay.filterTo = this.getTranslatedTodayValue(filterToDisplay.configsTo.calendarConfig.todayValue);
                }
              }
            }
            filterToDisplays.push(filterToDisplay);
          }
        }
      });
    }
    return filterToDisplays;
  }
  public static sortModelToFilterToDisplay(
    columnFilters: { items: ColumnFilterAutocomplete[] },
    s: CompiereDataGridRequestJSON
  ): SorterToDisplay[] {
    const sorterToDisplays: SorterToDisplay[] = [];
    if (s.sortModel) {
      s.sortModel.forEach((sortModelTmp) => {
        const columnFound = columnFilters.items.find(
          (columnFilter) => columnFilter.columnInfo.fieldEntity.field.ColumnName === sortModelTmp.colId
        );
        const base: SorterToDisplay = {
          column: columnFound ? columnFound : { id: sortModelTmp.colId, displayValue: sortModelTmp.colId, columnInfo: null },
          sortingType: undefined
        };
        const sortToDisplay: SorterToDisplay = Object.assign({}, base);
        sortToDisplay.sortingType = sortModelTmp.sort;
        sorterToDisplays.push(sortToDisplay);
      });
    }
    return sorterToDisplays;
  }

  public static groupModelToFilterToDisplay(
    columnFilters: { items: ColumnFilterAutocomplete[] },
    s: CompiereDataGridRequestJSON
  ): ColumnFilterAutocomplete[] {
    const groupToDisplays: ColumnFilterAutocomplete[] = [];
    if (s.rowGroupCols) {
      s.rowGroupCols.forEach((groupColTmp) => {
        const base = columnFilters.items.find((columnFilter) => columnFilter.id === groupColTmp.id);
        const groupToDisplay: ColumnFilterAutocomplete = Object.assign({}, base);
        groupToDisplays.push(groupToDisplay);
      });
    }
    return groupToDisplays;
  }

  /*
   * *****************************************************************
   * *****************************************************************
   * *****************************************************************
   * ********************* COMPIERE DATA GRID ************************
   * *****************************************************************
   * *****************************************************************
   * *****************************************************************
   */
  public static dataToDisplayToCompiereDataGrid(dataToDisplay: DataToDisplay, tabID: number): CompiereDataGridRequestJSON {
    const dataToTransform: DataToDisplay = this.transformDisplayOperators(cloneDeep(dataToDisplay));
    dataToTransform.filters = dataToTransform.filters.filter(
      (f) =>
        (f.filter !== undefined && f.filter !== null && f.filter !== '') ||
        (f.filterTo !== undefined && f.filterTo !== null && f.filterTo !== '')
    );
    return {
      label: dataToTransform.favorite,
      filterModel: this.filterToDisplayToFilterModel(dataToTransform),
      startRow: 0,
      endRow: this.config.getConstant('GridTabInfinityScrollUiComponent#cacheBlockSize'),
      entityId: tabID,
      windowType: CompiereDataGridType.WINDOW,
      rowGroupCols: dataToTransform.groups
        .filter((group) => group && group.id !== -1)
        .map((group) => ({
          id: group.id,
          displayName: group.displayValue,
          field: group.columnInfo.fieldEntity.field.ColumnName,
          aggFunc: ''
        })),
      sortModel: dataToTransform.sortings
        .filter((sorting) => sorting.column && sorting.column.id !== -1)
        .map((sorting) => ({
          colId: sorting.column.columnInfo ? sorting.column.columnInfo.fieldEntity.field.ColumnName : sorting.column.id,
          sort: sorting.sortingType
        })),
      pivotCols: dataToDisplay.notUFData ? (dataToDisplay.notUFData.pivotCols ? dataToDisplay.notUFData.pivotCols : []) : [],
      valueCols: dataToDisplay.notUFData ? (dataToDisplay.notUFData.valueCols ? dataToDisplay.notUFData.valueCols : []) : [],
      pivotMode: dataToDisplay.notUFData ? (dataToDisplay.notUFData.pivotMode ? true : false) : false
    };
  }

  public static filterToDisplayToFilterModel(dataToTransform: DataToDisplay): {
    [columnName: string]: CompiereDataGridFilterModel;
  } {
    const filterModel: { [columnName: string]: CompiereDataGridFilterModel } = {};
    dataToTransform.filters
      .filter((f) => f.column.id !== -1 && f.column.displayValue)
      .forEach((f) => {
        const columnName = f.column.columnInfo.fieldEntity.ColumnName
          ? f.column.columnInfo.fieldEntity.ColumnName
          : f.column.columnInfo.fieldEntity.field.ColumnName;
        const values =
          f.operator.operator.filterType === CompiereDataGridFilterType.DATE
            ? moment(
                f.configs?.calendarConfig?.todayMode
                  ? this.getTodayModeDate(f.configs.calendarConfig.todayValue, f.column.columnInfo.fieldEntity)
                  : f.filter
              )
                .format('YYYY-MM-DDTHH:mm:ss.SSS')
                .substring(0, 26) +
              '' +
              moment(
                f.configs?.calendarConfig?.todayMode
                  ? this.getTodayModeDate(f.configs.calendarConfig.todayValue, f.column.columnInfo.fieldEntity)
                  : f.filter
              )
                .format('YYYY-MM-DDTHH:mm:ss.SSS')
                .substring(
                  27,
                  moment(
                    f.configs?.calendarConfig?.todayMode
                      ? this.getTodayModeDate(f.configs.calendarConfig.todayValue, f.column.columnInfo.fieldEntity)
                      : f.filter
                  ).format('YYYY-MM-DDTHH:mm:ss.SSS').length
                )
            : Array.isArray(f.filter)
            ? f.filter.map((item) => (item instanceof Object ? item.id : item))
            : f.filter instanceof Object
            ? f.filter.id
            : f.operator.operator.type === 'contains'
            ? f.filter.trim()
            : f.filter;
        if (filterModel.hasOwnProperty(columnName)) {
          filterModel[columnName].values.push(values);
          filterModel[columnName].operators.push(f.operator.operator.type);
          filterModel[columnName].configs.push(f.configs);
        } else {
          filterModel[columnName] = {
            filterType: f.operator.operator.filterType,
            values: [values],
            operators: [f.operator.operator.type],
            configs: [f.configs]
          };
        }
        if (f.operator.operator.filterType === CompiereDataGridFilterType.NUMBER) {
          for (let i = 0; i < filterModel[columnName].values.length; i++) {
            if (Array.isArray(filterModel[columnName].values[i])) {
              filterModel[columnName].values[i] = filterModel[columnName].values[i].map((value) => {
                if (typeof value === 'string') {
                  value = value.replace(',', '.');
                }
                if (!isNaN(parseFloat(value))) {
                  value = parseFloat(value);
                } else {
                  value = 0;
                }
                return value;
              });
            } else {
              if (filterModel[columnName].values[i] instanceof String) {
                filterModel[columnName].values[i] = filterModel[columnName].values[i].replace(',', '.');
              }
              if (!isNaN(parseFloat(filterModel[columnName].values[i]))) {
                filterModel[columnName].values[i] = parseFloat(filterModel[columnName].values[i]);
              } else {
                filterModel[columnName].values[i] = 0;
              }
            }
          }
        }
      });
    return filterModel;
  }

  private static transformDisplayOperators(dataToDisplay: DataToDisplay): DataToDisplay {
    if (dataToDisplay.filters.filter((f) => f.column.id === -1 || f.operator.id === -1).length === 0) {
      const betweens = dataToDisplay.filters
        .map((f) => (f.operator.operator.type === 'between' ? f : undefined))
        .filter((f) => f !== undefined);

      betweens.forEach((f) => {
        const index = dataToDisplay.filters.findIndex((fil) => fil === f);
        if (f.filter !== undefined && f.filterTo !== undefined && f.filter !== null && f.filterTo !== null) {
          const borneInf: FilterToDisplay = {
            column: f.column,
            filter: f.filter,
            operator: f.operator,
            configs: f.configs
          };
          const borneSup: FilterToDisplay = {
            column: f.column,
            filter: f.filterTo,
            operator: f.operator,
            configs: f.configsTo
          };
          dataToDisplay.filters.splice(index, 1, borneInf, borneSup);
        } else if (f.filter !== undefined && f.filter !== null && (f.filterTo === undefined || f.filterTo === null)) {
          const operator = filterOperators.find(
            (op) => op.type === OperatorFilterType.BIGGER_EQUALS && op.filterType === f.column.columnInfo.filterType
          );
          const borneInf: FilterToDisplay = {
            column: f.column,
            filter: f.filter,
            operator: {
              id: operator.id,
              displayValue: operator.label,
              operator: operator
            },
            configs: f.configs
          };
          dataToDisplay.filters.splice(index, 1, borneInf);
        } else if ((f.filter === undefined || f.filter === null) && f.filterTo !== undefined && f.filterTo !== null) {
          const operator = filterOperators.find(
            (op) => op.type === OperatorFilterType.LESS_EQUALS && op.filterType === f.column.columnInfo.filterType
          );
          const borneSup: FilterToDisplay = {
            column: f.column,
            filter: f.filterTo,
            operator: {
              id: operator.id,
              displayValue: operator.label,
              operator: operator
            },
            configs: f.configsTo
          };
          dataToDisplay.filters.splice(index, 1, borneSup);
        }
      });
    }
    return cloneDeep(dataToDisplay);
  }

  private static getTodayModeDate(todayValue: string, fieldEntity?: any): Date {
    const regexToday = RegExp('^\\s*today\\s*(?<operation>[\\+|\\-]{0,1})\\s*$');
    const regexTodayNumber = RegExp('^\\s*today\\s*(?<operation>\\+|\\-)\\s*(?<number>[\\-]{0,1}\\d+)\\s*$'); 
    const regexTodayNumberUnit = RegExp('^\\s*today\\s*(?<operation>\\+|\\-)\\s*(?<number>[\\-]{0,1}\\d+)\\s*(?<unit>day|week|month|year)\\s*$');
    let date: Date;

    if (regexToday.test(todayValue)) {
      date = new Date();
    } else if (regexTodayNumber.test(todayValue)) {
      const result = regexTodayNumber.exec(todayValue);
      const { operation, number } = result.groups;
      date = moment(new Date())[operation === '+' ? 'add' : 'subtract'](number, 'day').toDate();
    } else if (regexTodayNumberUnit.test(todayValue)) {
      const result = regexTodayNumberUnit.exec(todayValue);
      const { operation, number, unit } = result.groups;
      date = moment(new Date())[operation === '+' ? 'add' : 'subtract'](number, <moment.unitOfTime.DurationConstructor>(unit)).toDate();
    }
    if (
      fieldEntity &&
      fieldEntity.field &&
      fieldEntity.field.AD_Reference_ID !== 16 &&
      fieldEntity.field.AD_Reference_ID !== 24
    ) {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
    }

    return date;
  }

  private static getUnit(unit: string): moment.unitOfTime.DurationConstructor {
    switch (unit) {
      case this.translator.instant('calendar.unit.day'):
        return 'day';
      case this.translator.instant('calendar.unit.week'):
        return 'week';
      case this.translator.instant('calendar.unit.month'):
        return 'month';
      case this.translator.instant('calendar.unit.year'):
        return 'year';
      default:
        return 'day';
    }
  }

  private static getTranslatedTodayValue(todayValue: string): string {
    const regexToday = RegExp('^\\s*today\\s*(?<operation>[\\+|\\-]{0,1})\\s*$');
    const regexTodayNumber = RegExp('^\\s*today\\s*(?<operation>\\+|\\-)\\s*(?<number>[\\-]{0,1}\\d+)\\s*$');
    const regexTodayNumberUnit = RegExp('^\\s*today\\s*(?<operation>\\+|\\-)\\s*(?<number>[\\-]{0,1}\\d+)\\s*(?<unit>day|week|month|year)\\s*$');

    if (regexToday.test(todayValue)) {
      return this.translator.instant('calendar.today');
    } else if (regexTodayNumber.test(todayValue)) {
      const { operation, number } = regexTodayNumber.exec(todayValue).groups;
      return `${this.translator.instant('calendar.today')} ${operation} ${number}`;
    } else if (regexTodayNumberUnit.test(todayValue)) {
      const { operation, number, unit } = regexTodayNumberUnit.exec(todayValue).groups;
      return `${this.translator.instant('calendar.today')} ${operation} ${number} ${this.translator.instant(
        'calendar.unit.' + unit
      )}`;
    } else {
      throw new IupicsMessage('todayValue', 'An error occured while trying to parse todayValue', 'error');
    }
  }

  /*
   * ************************************************************
   * ************************************************************
   * ************************************************************
   * ******************** FILTER UTILS **************************
   * ************************************************************
   * ************************************************************
   * ************************************************************
   */

  public static cleanDataToDisplay(dataToDisplay: DataToDisplay) {
    dataToDisplay.filters = dataToDisplay.filters.filter(
      (filter) =>
        filter.column &&
        filter.operator &&
        (filter.column.id !== -1 ||
          filter.operator.id !== -1 ||
          filter.filter !== '' ||
          (filter.operator.id !== -1 && filter.operator.operator.isRange && filter.filterTo !== ''))
    );
    dataToDisplay.groups = dataToDisplay.groups.filter((group) => group.id !== -1);
    dataToDisplay.sortings = dataToDisplay.sortings.filter((sorting) => sorting.column && sorting.column.id !== -1);
    return dataToDisplay;
  }
}

export interface DataToDisplay {
  isDefault?: boolean;
  favorite: string;
  groups: ColumnFilterAutocomplete[];
  filters: FilterToDisplay[];
  sortings: SorterToDisplay[];
  notUFData: { pivotCols?: CompiereDataGridGroupModel[]; valueCols?: CompiereDataGridGroupModel[]; pivotMode?: boolean };
}

export interface SorterToDisplay {
  column: ColumnFilterAutocomplete;
  sortingType: CompiereDataGridSortModelType;
}

export interface FilterToDisplay {
  column: ColumnFilterAutocomplete;
  operator: OperatorFilterAutocomplete;
  filter: any;
  filterTo?: any;
  configs?: any;
  configsTo?: any;
}

export interface FilterChip {
  displayValue: string;
  icon: string;
  type: string;
  index: number;
  columnName?: string;
}
