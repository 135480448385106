import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ViewType } from '@iupics-components/models/view-type.enum';
import { Global } from '@iupics-manager/models/global-var';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'iu-menu-bar-ui',
  templateUrl: './menu-bar-ui.component.html',
  styleUrls: ['./menu-bar-ui.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MenuBarUiComponent implements OnInit {
  @Input() isFromForm = false;
  @Input() activeTabID: string;
  @Input() gridView: any;
  /**
   * Sers à cacher le menu-bar detail pour les forms
   */
  @Input() hasUniversalFilter = true;
  @Input() IsDeleteable = true;
  @Input() IsInsertRecord = true;
  @Input() isReadOnly = false;
  @Input() isSplitView = false;
  @Input() isTabTopLevel = true;
  @Input() tabId: number;
  @Input() visibleButton = true;
  @Input() visibleTreeButton: boolean;

  @Output() changeViewEvent = new EventEmitter();
  @Output() filterChange = new EventEmitter<boolean>();
  @Output() newEmitter = new EventEmitter();
  @Output() openExportDataModalEmitter = new EventEmitter();
  @Output() refreshEmitter = new EventEmitter();
  @Output() removeEmitter = new EventEmitter();

  visibleButtonMultiSelection = false;
  tooltipLabels: any;
  isMobile = Global.isMobile();
  showMoreAction = false;
  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.tooltipLabels = {
      add: this.translate.instant('menuBarUi.add'),
      delete: this.translate.instant('menuBarUi.delete'),
      refresh: this.translate.instant('menuBarUi.refresh'),
      export: this.translate.instant('menuBarUi.export'),
      gridView: this.translate.instant('menuBarUi.gridView'),
      kanbanView: this.translate.instant('menuBarUi.kanbanView'),
      calendarView: this.translate.instant('menuBarUi.calendarView'),
      chartView: this.translate.instant('menuBarUi.chartView'),
      treeView: this.translate.instant('menuBarUi.treeView'),
      filter: this.translate.instant('menuBarUi.filter'),
      search: this.translate.instant('menuBarUi.search')
    };
  }
  onShowMoreAction(event) {
    event.stopPropagation();
    this.showMoreAction = !this.showMoreAction;
  }

  setVisibleButton(visible: boolean) {
    this.visibleButton = visible;
  }

  setVisibleButtonMultiSelection(visible: boolean) {
    this.visibleButtonMultiSelection = visible;
  }

  swicthToGridView() {
    this.changeViewEvent.emit(ViewType.GRID);
  }

  switchToKanbanView() {
    this.changeViewEvent.emit(ViewType.KANBAN);
  }

  switchToCalendarView() {
    this.changeViewEvent.emit(ViewType.CALENDAR);
  }

  switchToChartView() {
    this.changeViewEvent.emit(ViewType.CHART);
  }

  switchToTreeView() {
    this.changeViewEvent.emit(ViewType.TREE);
  }
}
