import { AfterViewInit, Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { TranslateService } from '@ngx-translate/core';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import {
  CompiereDataGridFilterType,
  DataStoreRequest
} from '@compiere-ws/models/compiere-data-json';

@Component({
  selector: 'iu-create-from-shipment-window-ui',
  templateUrl: './create-from-shipment-window-ui.component.html',
  styleUrls: ['./create-from-shipment-window-ui.component.scss']
})
export class CreateFromShipmentWindowUiComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit {
  orderGrid: any;
  blanketOrderGrid: any;
  invoiceGrid: any;
  orderSelections: any;
  blanketOrderSelections: any;
  invoiceSelections: any;

  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
    this.isModal = false;
  }
  ngOnInit() {
    super.ngOnInit();
    this.dataStore.data['C_Order_ID'] = null;
    this.dataStore.data['C_Invoice_ID'] = null;
    this.dataStore.data['C_BlanketOrder_ID'] = null;
    /**
     * @start_custo_code
     */
    if (this.dataStore.data['IsSOTrx'] === 'N') {
      this.fields.find((field) => {
        if (field.data.columnName === 'C_Order_ID') {
          field.data.label = this.translateService.instant('createFrom.purchaseOrder');
        }
      });
      this.dataContainers.find((col) => {
        if (col.data.columnName === 'C_Order_ID') {
          col.data.label = this.translateService.instant('createFrom.purchaseOrder');
        }
      });
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.dataStore.data['IsSOTrx'] === 'N') {
      this.gridViews.forEach((grid) => {
        grid.GridTabInfinityScrollUiComponent.columns.find((column) => {
          if (column.field === 'C_Order_ID') {
            column.headerName = this.translateService.instant('createFrom.purchaseOrder');
          }
        });
        grid.GridTabInfinityScrollUiComponent.columns.find((column) => {
          if (column.field === 'C_OrderLine_ID') {
            column.headerName = this.translateService.instant('createFrom.purchaseOrderLine');
          }
        });
      });
    }
  }
  /**
   * @end_custo_code
   */
  notifyFromDataChange(item: any) {
    if (item.data.columnName === 'C_Order_ID') {
      this.dataStore.data['C_Invoice_ID'] = null;
      this.dataStore.data['C_BlanketOrder_ID'] = null;
      this.clearSelections();
      if (this.dataStore && this.dataStore.data['C_Order_ID']){
        this.setPoRefarence();
     } 
    } else if (item.data.columnName === 'C_BlanketOrder_ID') {
      this.dataStore.data['C_Invoice_ID'] = null;
      this.dataStore.data['C_Order_ID'] = null;
      this.clearSelections();
    } else if (item.data.columnName === 'C_Invoice_ID') {
      this.dataStore.data['C_Order_ID'] = null;
      this.dataStore.data['C_BlanketOrder_ID'] = null;
      this.clearSelections();
    }
    super.notifyFromDataChange(item);
  }

  private setPoRefarence() {
    if (this.getFormStoreData('C_Order_ID')) {
      const v_C_Order_ID = this.getFormStoreData('C_Order_ID')['id'];
      
      const request: DataStoreRequest = {
        windowId: this.dataStore.key.windowId,
        record_id: this.dataStore.key.recordId.toString(),
        parent_constraint: undefined,
        compiereRequest: {
          tableName: 'C_Order',
          startRow: 0,
          endRow: 1,
          filterModel: {
            C_Order_ID: {
              filterType: CompiereDataGridFilterType.SET,
              values: [v_C_Order_ID],
              operators: [OperatorFilterType.EQUALS]
            }
          }
        }
      };


      //PoReference
      let tableName =
        "( SELECT ord.PoReference, ord.C_Order_ID FROM C_Order ord WHERE ord.C_Order_ID ="+v_C_Order_ID+" "
         + ") cpt";

      request.compiereRequest.tableName = tableName;

      this.store.getDataGrid(request).subscribe(response => {
        if (response && response.data && response.data[0]) {
            this.dataStore.data['POReference'] = response.data[0]['POREFERENCE'];
        }
        else {
          this.dataStore.data['POReference'] = null;
        }
       this.dataContainers.forEach((datacontainer) => {
          if (datacontainer && datacontainer.data && datacontainer.data.columnName === 'POReference') {
            datacontainer.updateStore(this.dataStore.data['POReference']);
          }
        });  
      });
    }
  }
  
  clearSelections() {
    this.dataStore.data['selections'].forEach((grid) => {
      grid['selection'] = [];
    });
    this.gridViews.forEach((grid) => {
      grid.GridTabInfinityScrollUiComponent.agGrid.api.deselectAll();
    });
  }
}
