<span
  [style.backgroundColor]="backgroundColorCss"
  [style.display]="displayCss"
  [style.width]="overridedCSS?.width"
  class="iu-input-text-ui"
  [pTooltip]="tooltip"
  tooltipPosition="top"
  life="3"
  showDelay="500"
  tooltipZIndex="2100"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
>
  <label
    *ngIf="this.isLabelDisplay && label !== undefined && label !== null && label.trim().length > 0"
    [style.font-size]="overridedCSS?.labelFontSize"
    >{{ label | textLimit: this.elementRef.nativeElement.firstElementChild.firstElementChild.clientWidth / 7 }}</label
  >
  <input
    #input
    (change)="dataChange(input.value); fieldValueChange.emit(input.value)"
    (contextmenu)="onContextMenu($event)"
    (press)="onContextMenu($event)"
    [(ngModel)]="fieldValue"
    [readonly]="isReadOnly"
    [tabindex]="isReadOnly ? '-1' : null"
    [ngClass]="[hasConflict ? 'iu-field-conflict-text' : '', mandatoryCss, 'ui-corner-all']"
    [type]="data ? data.fieldType : 'text'"
    autocomplete="new-password"
    pInputText
    placeholder="{{ placeHolder }}"
    value=""
    (click)="resetPassWord()"
    (blur)="changePassWord()"
    (keydown.enter)="enterKey.emit($event)"
    [style.font-size]="overridedCSS?.inputFontSize"
    [style.height]="overridedCSS?.inputHeight"
    data-cy="input-text"
    [attr.data-cy-columnName]="data ? data.columnName : columnName"
  />
  <i
    *ngIf="hasConflict && dataStored"
    #spanInput
    class="ui-inputgroup-addon rtconflict fas fa-exclamation-triangle iconClass"
    (click)="showConflictPanel($event)"
  ></i>
  <p-overlayPanel #opValuePref *ngIf="showValuePrefPanel"  [baseZIndex]="6000" [dismissable]="true" appendTo="body">
    <iu-value-preference-panel
      [overlayPanel]="opValuePref"
      [fieldValue]="fieldValue"
      [dataStored]="this.dataStored"
      [sourceComponent]="this"
      [data]="this.data"
      (closeEmitter)="toggleValuePreferencePanel($event)"
    ></iu-value-preference-panel>
  </p-overlayPanel>
</span>

<p-overlayPanel #opConflict>
  <a class="data-conflict" (click)="chooseData($event, (dataStored?.data)[data.columnName], true); opConflict.hide($event)">{{
    (dataStored?.data)[data.columnName]
  }}</a>
  | <a class="data-conflict" (click)="chooseData($event, conflictedData, false); opConflict.hide($event)">{{ conflictedData }}</a>
</p-overlayPanel>
