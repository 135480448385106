import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompiereLanguage } from '@compiere-ws/models/compiere-language-json';
import { IupicsCookieService, LocalStorageIupics } from '@iupics-manager/managers/security-manager/cookies/iupics-cookie.service';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { AccountSelectorComponent } from '@login-page/components/account-selector/account-selector.component';
import { HomePageService } from '@login-page/controllers/home-page.service';
import { UserAccount } from '@login-page/models/user-account.';
import { environment } from 'environments/environment';

@Component({
  selector: 'iu-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomePageComponent implements OnInit, OnDestroy {
  //#region copyright
  officialApizWebSiteUrl = `${environment.webSiteUrl}`;
  isCusto = environment.CUST_version !== '{CUST_version}';
  version = this.isCusto ? `${environment.CUST_version} (v${environment.STD_version})` : `v${environment.STD_version}`;
  logoSrc = `assets/themes/iupics/img/logo-apiz.svg`;
  isDefaultLogo = this.logoSrc.endsWith('logo-apiz.svg') ?? true;
  //#endregion copyright

  login: string;
  accounts: UserAccount[];
  password: string;
  isRememberUser = true;
  defaultLanguage: CompiereLanguage;
  subscriptions: any[] = [];
  url: string;
  @ViewChild('accountsRegistered')
  accountsRegistered: AccountSelectorComponent;
  @Output()
  validateLoginEmitter: EventEmitter<any> = new EventEmitter();
  isViewAllComponent = false;
  successMessage: IupicsMessage;
  constructor(
    private controller: HomePageService,
    private route: ActivatedRoute,
    private router: Router,
    private cookieService: IupicsCookieService
  ) {}

  ngOnInit() {
    // Get possible url
    this.route.queryParams.subscribe((params) => {
      // Defaults to 0 if no query param provided.
      this.url = params['redirect'] || '/workspace';

      if (
        this.cookieService.checkLS(LocalStorageIupics.access_token) &&
        this.cookieService.checkLS(LocalStorageIupics.crypto_key)
      ) {
        this.router.navigateByUrl(this.url);
      }
    });
    this.validateLoginEmitter.subscribe(() => {
      this.router.navigateByUrl(this.url);
    });
    // Generate Crypto Key
    this.controller.generateCryptoKey();
    // Generate Device ID
    this.controller.generateDeviceId();
    // Init Application language and select prefered language
    this.controller.initLanguages();
    // Get account registered on navigator
    this.accounts = this.controller.getAccountsSaved();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  confirmLogin(event: MouseEvent | KeyboardEvent) {
    event.preventDefault();
    if (this.accounts) {
      this.controller.verifySemiAuthentification(
        this.accountsRegistered.defaultAccount,
        this.password,
        this.validateLoginEmitter
      );
    } else {
      this.controller.verifyFullAuthentification(this.login, this.password, this.validateLoginEmitter, this.isRememberUser);
    }
  }

  cleanAccounts() {
    this.accounts = undefined;
    this.controller.errorMessage = undefined;
    this.login = undefined;
    this.password = undefined;
  }

  getErrorMessage(): string {
    return this.controller.errorMessage;
  }

  viewAllComponent() {
    this.isViewAllComponent = !this.isViewAllComponent;
  }
}
