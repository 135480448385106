import { CellEditingStoppedEvent } from '@ag-grid-enterprise/all-modules';
import { Component, ComponentFactoryResolver, Input, OnInit } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { CacheManagerService, CacheName } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { TranslateService } from '@ngx-translate/core';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-inout-confirm-batch',
  templateUrl: './inout-confirm-batch.component.html',
  styleUrls: ['./inout-confirm-batch.component.scss']
})
export class InOutConfirmBatchComponent extends SpecificWindowUiComponent implements OnInit {

  numberFormat = '';
  @Input()
  _precision = -1;
  set precision(value: any) {
    this._precision = value;
    if (value >= 0) {
        const stdPrecicion = this.connectorService.getIupicsUserContext()['#StdPrecision'];
        if (stdPrecicion != null && stdPrecicion != undefined && value > stdPrecicion) {
          this.numberFormat = `1.${stdPrecicion}-${value}`;
        } else {
          this.numberFormat = `1.${value}-${value}`;
        }
    }
  }

  get precision() {
    return this._precision;
  }

  constructor(
    protected windowFactory: WindowFactoryService,
    protected resolver: ComponentFactoryResolver,
    protected uiCreator: UICreatorService,
    protected store: DataStoreService,
    protected processService: CompiereProcessService,
    protected socketService: SocketService,
    protected connectorService: SecurityManagerService,
    protected translator: TranslateService,
    private messageManager: MessageManagerService,
    progressService: ProcessInProgressService,
    private uiCreatorService: UICreatorService,
    protected cacheService: CacheManagerService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translator
    );
  }

  ngOnInit() {
    this.uiCreatorService.getSpecificWindow(this.formId).subscribe((specificWindow) => {
      specificWindow.items.forEach((item) => {
        if (item !== undefined) {
          if (item.component === 'GridViewUiComponent') {
            item.data['editorColumns'] = ['XX_IsDiffBalance'];
            item.data.hasCheckbox = true;
            const colConfirmedQty = item.data.columnsTableHeader.find(function (element) {
              return element.field === 'ConfirmedQty';
            });
            if (colConfirmedQty) {
              colConfirmedQty.editable = true;
            }
          }
        }
      });
      super.ngOnInit();
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    // * initialisation gridViews
    this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.gridOptions.singleClickEdit = true;
    this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.gridOptions.stopEditingWhenGridLosesFocus = true;
  }

  notifyFromCellClicked(gridView: GridViewUiComponent, event: any) {
    if (event && event.column.colId !== null && event.column.colId !== undefined) {
      if (['XX_IsDiffBalance'].includes(event.column.colId)) {
        event.data['XX_IsDiffBalance'] = event.value === 'N' ? 'Y' : 'N';
        event.node.setData(event.data);
      }
    }
  }

  onCellEdited(event: CellEditingStoppedEvent) {
    if (!(event.column.getColId() === 'XX_IsDiffBalance')) {
      if (event.newValue != event.oldValue) {
        event.node.setSelected(true);
        event.data['ValueChanged'] = 'Y';
      }
      // change event make put value as string so we parse it to int
      if (event.value === null) {
        event.value = 0;
      }

      const cacheName = CacheName.UOM;
      const id = event.data['C_UOM_ID'];
      if (cacheName !== null && id > 0) {
        this.subscriptions.push(
          this.cacheService.getPrecision(cacheName, id).subscribe((precision) => {
            this.precision = precision;
            event.value = parseFloat(parseFloat(event.newValue).toFixed(this.precision));
            event.data[event.colDef.field] = parseFloat(parseFloat(event.newValue).toFixed(this.precision));
            const targetQty = event.data['TargetQty'];
            const scrappedQty = event.data['ScrappedQty'];
            event.data['DifferenceQty'] = targetQty - scrappedQty - event.value;
            event.node.setData(event.data);
          })
        );
      } else {
            event.value = parseFloat(event.newValue);
            event.data[event.colDef.field] = parseFloat(event.newValue);
            const targetQty = event.data['TargetQty'];
            const scrappedQty = event.data['ScrappedQty'];
            event.data['DifferenceQty'] = targetQty - scrappedQty - event.value;
            event.node.setData(event.data);
      }
  }
  }
  checkBeforeProcessing() {
    const grid = this.dataStore.data['selections'][0];
    grid['selection'] = [];
    const concernedDocIds = [];
    this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getSelectedNodes().forEach((node) => {
      if (!concernedDocIds.find((id) => id === node.data.M_InOutConfirm_ID)) {
        concernedDocIds.push(node.data.M_InOutConfirm_ID);
      }
    });
    this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.forEachNode((node) => {
      if (
        node.isSelected() ||
        (concernedDocIds.find((id) => id === node.data.M_InOutConfirm_ID) && node.data.ValueChanged === 'Y')
      ) {
        grid['selection'].push(node.data);
      }
    });
    return grid['selection'].length > 0;
  }
}
