import { AfterViewInit, Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import {
  CompiereDataGridFilterType,
  CompiereDataGridResponseJSON,
  DataStore,
  DataStoreRequest
} from '@compiere-ws/models/compiere-data-json';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { zip } from 'rxjs';
import { AutocompleteRendererComponent } from '../renderer/autocomplete-renderer.component';
import { ButtonRendererComponent } from '../renderer/button-renderer.component';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-bpartner-balance',
  templateUrl: './bpartner-balance.component.html',
  styleUrls: ['./bpartner-balance.component.scss']
})
export class BpartnerBalanceComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit {
  frameworkComponents: any;
  dataStore: DataStore;

  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    processService: CompiereProcessService,
    protected store: DataStoreService,
    protected translateService: TranslateService,
    private messageManager: MessageManagerService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    private uiCreatorService: UICreatorService,
    progressService: ProcessInProgressService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      autocompleteRenderer: AutocompleteRendererComponent
    };
  }

  ngOnInit(): void {
    super.ngOnInit();
    const ad_org = this.getFormStoreData('AD_Org_ID');
    if (ad_org) {
      if (ad_org.id === 0) {
        this.setExcludedFiltersToGrid('BP accounting_table', ['AD_Org_ID']);
      } else {
        this.removeExcludedFiltersToGrid('BP accounting_table');
      }
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.dataContainers.forEach((dataContainer) => {
      if (['BASE_DR', 'DueTotal', 'NotDueTotal'].includes(dataContainer.data.columnName)) {
        dataContainer.initCurrency(this.connectorService.getIupicsUserContext()['$C_Currency_ID']);
      }
      dataContainer.setNewData(this.dataStore);
    });
    // Global.workspace = this;
  }

  notifyFromGridRefresh(gridView: GridViewUiComponent, dataStoreRequest: DataStoreRequest) {
    const obsZip = [];
    this.initBalance();
    // balance
    dataStoreRequest.compiereRequest.sortModel = undefined;
    dataStoreRequest.compiereRequest.valueCols = [
      {
        id: 'OPENAMT_ORIGIN',
        aggFunc: 'sum',
        displayName: 'OPENAMT_ORIGIN',
        field: 'OPENAMT_ORIGIN'
      }
    ];

    obsZip.push(this.store.getDataGrid(dataStoreRequest));

    // Balance (devise base)
    const dataStoreRequest2 = cloneDeep(dataStoreRequest);
    dataStoreRequest2.compiereRequest.sortModel = undefined;
    dataStoreRequest2.compiereRequest.valueCols = [
      {
        id: 'OPENAMT_BASE',
        aggFunc: 'sum',
        displayName: 'OPENAMT_BASE',
        field: 'OPENAMT_BASE'
      }
    ];
    obsZip.push(this.store.getDataGrid(dataStoreRequest2));

    // total non echu && mintant ouvert total
    const dataStoreRequest3 = cloneDeep(dataStoreRequest);
    dataStoreRequest3.compiereRequest.sortModel = undefined;
    dataStoreRequest3.compiereRequest.valueCols = [
      {
        id: 'BASE_DR',
        aggFunc: 'sum',
        displayName: 'Balance (devise base)',
        field: 'BASE_DR'
      },
      {
        id: 'BASE_CR',
        aggFunc: 'sum',
        displayName: 'En-cours autorisé',
        field: 'BASE_CR'
      }
    ];
    obsZip.push(this.store.getDataGrid(dataStoreRequest3));

    const dataStoreRequest4 = cloneDeep(dataStoreRequest2);
    dataStoreRequest4.compiereRequest.sortModel = undefined;
    dataStoreRequest4.compiereRequest.filterModel['DueDate'] = {
      filterType: CompiereDataGridFilterType.DATE,
      values: [
        moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS').substring(0, 26) +
          '' +
          moment(new Date())
            .format('YYYY-MM-DDTHH:mm:ss.SSS')
            .substring(27, moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS').length)
      ],
      operators: [OperatorFilterType.LESS_EQUALS]
    };

    obsZip.push(this.store.getDataGrid(dataStoreRequest4));
    this.subscriptions.push(
      zip(...obsZip).subscribe(([response1, response2, response3, response4]: CompiereDataGridResponseJSON[]) => {

        // Balance en devise
        if (response1 && response1.data && response1.data[0] && response1.data[0]['OPENAMT_ORIGIN'] ) {
          const dueTotal = response1.data[0]['OPENAMT_ORIGIN'];
          this.setFormStoreData('Balance', dueTotal.toFixed(2));
        }

        // Balance (devise base)
        if (response2 && response2.data && response2.data[0] && response2.data[0]['OPENAMT_BASE']) {
          const dueTotal = response2.data[0]['OPENAMT_BASE'];
          this.setFormStoreData('BASE_DR_en_tete', dueTotal.toFixed(2));
        }

        // montantOuvertTotal
        if (response4 && response4.data && response4.data[0] && response4.data[0]['OPENAMT_BASE']) {
          const dueTotal = response4.data[0]['OPENAMT_BASE'];
          this.setFormStoreData('DueTotal', dueTotal.toFixed(2));
        }

        // total non echu && mintant ouvert total
        if (response3 && response3.data && response3.data[0] && response3.data[0]['BASE_DR'] && response3.data[0]['BASE_CR']) {
          const baseDR = response3.data[0]['BASE_DR'];
          const baseCR = response3.data[0]['BASE_CR'];
          const BALANCEBASE = baseDR - baseCR;
          this.setFormStoreData('BASE_DR', BALANCEBASE.toFixed(2));
        }

        const notDueTotal = this.getFormStoreData('BASE_DR_en_tete', 0) - this.getFormStoreData('DueTotal', 0);
        this.setFormStoreData('NotDueTotal', notDueTotal.toFixed(2));
        this.setDataContainersValueWithChangedStore();
      })
    );
    super.notifyFromGridRefresh(gridView, dataStoreRequest);
  }

  notifyFromDataChange(item: any, vcrIndex?: number) {
    this.initBalance();
    if (item.data['columnName'] === 'OnlyOpen' && this.dataStore) {
      if (!this.dataStore.data['OnlyOpen'] && this.dataStore.data['OnlyOpen'] === 'N') {
        this.setExcludedFiltersToGrid('BP accounting_table', ['OnlyOpen']);
      } else {
        this.removeExcludedFiltersToGrid('BP accounting_table');
      }
    }
    if ((item.data['columnName'] === 'C_BPartner_ID' && this.dataStore && this.dataStore.data['C_BPartner_ID'])
      || (item.data['columnName'] === 'Type_piece' && this.dataStore && this.dataStore.data['Type_piece'])) {
      this.setBPartnerInfos();
    }
    if (this.dataStore && this.dataStore.data['AD_Org_ID']) {
      const ad_orgIdValue = this.getFormStoreData('AD_Org_ID');
      if (ad_orgIdValue && ad_orgIdValue.id === 0) {
        this.setExcludedFiltersToGrid('BP accounting_table', ['AD_Org_ID']);
      } else {
        this.removeExcludedFiltersToGrid('BP accounting_table');
      }
    }
    super.notifyFromDataChange(item);
  }

  initBalance() {
    this.setFormStoreData('Balance', 0.00);
    this.setFormStoreData('BASE_DR_en_tete', 0.00);
    this.setFormStoreData('DueTotal', 0.00);
    this.setFormStoreData('BASE_DR', 0.00);
    this.setFormStoreData('NotDueTotal', 0.00);
  }

  private setBPartnerInfos() {
    if (this.getFormStoreData('C_BPartner_ID')) {
      const v_C_BPartner_ID = this.getFormStoreData('C_BPartner_ID')['id'];
      let v_Type_piece;
      if (this.getFormStoreData('Type_piece')) {
        v_Type_piece = this.getFormStoreData('Type_piece')['id'];
      }
      const request: DataStoreRequest = {
        windowId: this.dataStore.key.windowId,
        record_id: this.dataStore.key.recordId.toString(),
        parent_constraint: undefined,
        compiereRequest: {
          tableName: 'C_BPartner',
          startRow: 0,
          endRow: 1,
          filterModel: {
            C_BPartner_ID: {
              filterType: CompiereDataGridFilterType.SET,
              values: [v_C_BPartner_ID],
              operators: [OperatorFilterType.EQUALS]
            }
          }
        }
      };
      this.store.getDataGrid(request).subscribe(response => this.setFormStoreData('BASE_CR', response.data[0]['BASE_CR']));

      // account
      const tableName =
        "( SELECT cva.Account_ID , bp.C_Bpartner_ID, bp.isVendor, bp.isCustomer FROM C_BPartner bp    left  join c_bp_customer_acct bpc on (bp.C_BPartner_iD = bpc.C_BPartner_iD  AND bp.IsCustomer= 'Y' "
        + (this.getFormStoreData('Type_piece') ? " AND '" + v_Type_piece + "' in ('AR', 'Al')" : '')
        + ' ) '
        + " left join c_bp_vendor_acct bpv on (bp.C_BPartner_iD = bpv.C_BPartner_iD  AND bp.IsVendor = 'Y' "
        + (this.getFormStoreData('Type_piece') ? " AND '" + v_Type_piece + "' in ('AP', 'Al')" : '')
        + ' ) '
        + ' INNER JOIN c_validcombination cva ON ( cva.c_validcombination_id = COALESCE(bpc.c_receivable_acct, bpv.v_liability_acct))'
        + ' where bp.c_bpartner_id = ' + v_C_BPartner_ID + ' '
        + ') cpt';

      request.compiereRequest.tableName = tableName;

      this.store.getDataGrid(request).subscribe(response => {
        if (response && response.data && response.data[0]) {
          if (response.data[0]['ISVENDOR'] === 'Y' && response.data[0]['ISCUSTOMER'] === 'Y' && (v_Type_piece === 'Al' || !v_Type_piece)) {
            this.dataStore.data['Account_ID'] = null;
          } else {
            this.dataStore.data['Account_ID'] = response.data[0]['ACCOUNT_ID'];
          }
        } else {
          this.dataStore.data['Account_ID'] = null;
        }
      }
      );
    }
  }

}
