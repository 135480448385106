import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { PoService } from '@compiere-ws/services/po/po.service';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'iu-input-switch-ui',
  templateUrl: './input-switch-ui.component.html',
  styleUrls: ['./input-switch-ui.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InputSwitchUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  @Input()
  columnName: string;
  @ViewChild('spanRef', { static: true })
  spanRef: ElementRef;
  dataContainers: AbstractDataContainerCallout;
  set checked(value: boolean) {
    this.fieldValue = value ? 'Y' : 'N';
    value ? this.dataChange('Y') : this.dataChange('N');
  }

  get checked() {
    return this.fieldValue === 'Y' ? true : false;
  }

  @ViewChild('opConflict', { static: true })
  opConflict: OverlayPanel;

  constructor(
    public elementRef: ElementRef,
    public store: DataStoreService,
    protected connectorService: SecurityManagerService,
    public cmService: ContextMenuService,
    public uiCreatorService: UICreatorService,
    renderer: Renderer2,
    protected po: PoService,
    protected cacheService: CacheManagerService

  ) {
    super(elementRef, connectorService, cmService, store, uiCreatorService, renderer, po, cacheService);
    this.isSwitchField = true;
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.isStandalone) {
      if (this.data && this.data.label) {
        this.label = this.data.label;
      }
      if (this.cssClass !== undefined) {
        this.cssGrid = this.cssClass;
      }
      this.isSwitchField = true;
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (!this.isStandalone) {
      let parentComp = this.DOMParentComponent;
      while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
        parentComp = parentComp.DOMParentComponent;
      }
      if (parentComp instanceof EditTabUiComponent) {
        this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
      }
      if (this.data && this.data.callouts && this.data.callouts.callouts) {
        this.data.callouts.callouts.forEach((callout) => {
          this.spanRef.nativeElement.addEventListener(callout.calloutevent, () => {
            const calloutFn = new Function('Field', 'Fields', 'DBSelect', callout.script);
            calloutFn(this, this.dataContainers, this.uiCreatorService);
          });
        });
      }
      // permet de setter les valeurs venant de l'url d'un process
      if (this.parentProcess) {
        const value = this.parentProcess.paramsMap.get(this.data.columnName);
        if (value !== undefined) {
          (<string>value).toUpperCase() === 'Y' ? (this.checked = true) : (this.checked = false);
        }
      }
    }
  }

  showConflictPanel(ev) {
    ev.target.getBoundingClientRect = function () {
      return { top: this.offsetTop, left: this.offsetLeft };
    };
    this.opConflict.toggle(ev);
  }
}
