// TODO REMOVE AFTER RELEASE 3.10.0
import { Pipe, PipeTransform } from '@angular/core';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
  name: 'docServerFormatValue'
})
export class DocServerFormatValuePipe implements PipeTransform {
  constructor(private translateService: TranslateService , protected connectorService: SecurityManagerService) {}

  transform(value: any, referenceId: number): Promise<any> {
    return this.getFormatFromReference(value, referenceId);
  }
  private getFormatFromReference(value: any, referenceId: number) {
    if (value !== null && value !== undefined) {
      return referenceId === 20
        ? value.toLowerCase() === 'y'
          ? this.translateService.instant('docServerFormatValue.yes')
          : this.translateService.instant('docServerFormatValue.no')
        : referenceId === 15 || referenceId === 16 || referenceId === 24
        ? this.parseDate(value, referenceId)
        : referenceId === 12 || referenceId === 37 || referenceId === 22 || referenceId === 29 || referenceId === 11
        ? this.parseNumber(value, referenceId)
        : (referenceId >= 17 && referenceId <= 19) ||
          referenceId === 30 ||
          referenceId === 42 ||
          referenceId === 27 ||
          referenceId === 31 ||
          referenceId === 25 ||
          referenceId === 13
        ? value
        : value;
    } else { return value; }
  }
  private parseNumber(value: any, referenceId: number) {
    const floatValue = value && value.replace ? parseFloat(value.replace(',', '.')) : value;
    if (floatValue === null || floatValue === undefined || isNaN(parseFloat(floatValue))) {
      return value;
    } else {
      if (referenceId === 11) {
        return parseFloat(floatValue.toFixed());
      }
      if (referenceId === 12) {
        return parseFloat(floatValue.toFixed(2));
      }
      return floatValue;
    }
  }
  private parseDate(value: any, referenceId: number) {
    let date;
    try {
      let iupicsLocale = this.connectorService.getIupicsDefaultLanguage().iso_code.replace('_', '-');
      if (iupicsLocale === 'fr-FR') {
        iupicsLocale = 'de-CH';
      }
      if (referenceId === 15) {
        /* Display Type 15	Date	*/
        const D = new Date(value);
        if (this.isValidDate(D)) {
          date = D.toLocaleDateString(iupicsLocale, { year: 'numeric', month: '2-digit', day: 'numeric' });
        } else {
          date = new Date(+value).toLocaleDateString(iupicsLocale, { year: 'numeric', month: '2-digit', day: 'numeric' });
        }
      } else if (referenceId === 16) {
        /* Display Type 16	DateTime	*/
        const D = new Date(value);
        if (this.isValidDate(D)) {
          date = D.toLocaleString(iupicsLocale, { year: 'numeric', month: '2-digit', day: 'numeric', hour : '2-digit', minute : '2-digit', second : '2-digit'});
        } else {
          date = new Date(+value).toLocaleString(iupicsLocale, { year: 'numeric', month: '2-digit', day: 'numeric', hour : '2-digit', minute : '2-digit', second : '2-digit'});
        }
      } else if (referenceId === 24) {
        /* Display Type 24	Time	*/
        const D = new Date(value);
        if (this.isValidDate(D)) {
          date = D.toLocaleTimeString(iupicsLocale, { hour : '2-digit', minute : '2-digit'});
        } else {
          date = new Date(+value).toLocaleTimeString(iupicsLocale, { hour : '2-digit', minute : '2-digit'});
        }
      }
    } catch (error) {
      date = value;
    }
    return date;
  }

  private isValidDate(d) {
    return d instanceof Date && !isNaN(d.getTime());
  }
}
