import { AfterViewInit, Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { TranslateService } from '@ngx-translate/core';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-related-products',
  templateUrl: './related-products.component.html',
  styleUrls: ['./related-products.component.scss']
})
export class RelatedProductsComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit  {
  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    private messageManager: MessageManagerService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
    this.isModal = false;
    this.customDesignArray.push(
      {
        vcr: 'vcrLeft',
        type: CustomDesignItemType.FIELD,
        columnName: 'M_Product_ID',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrBot',
        type: CustomDesignItemType.GRID,
        tableName: 'RelatedProduct Table',
        cssClass: 'p-col-12',
        shouldSelectFirst: true
      },
      {
        vcr: 'vcrButtons',
        type: CustomDesignItemType.FIELD,
        columnName: 'Replace',
        cssClass: 'p-col-12 p-md-3 p-lg-2'
      }
    );
  }
  ngOnInit() {
    super.ngOnInit();
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'p-col-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  notifyFromGridRefresh(gridView: GridViewUiComponent) {
    if (this.gridViews[0] && this.gridViews[0].GridTabInfinityScrollUiComponent) {
      this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getDisplayedRowAtIndex(0).data.Quantity = this.dataStore.data.QtyEntered;
      this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.gridOptions.api.refreshCells({columns : ['Quantity']}) ;
    }
  }
}
