<span
  [style.display]="displayCss"
  [pTooltip]="tooltip"
  life="3"
  tooltipPosition="top"
  showDelay="500"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
  tooltipZIndex="2100"
  #input
  (contextmenu)="onContextMenu($event)"
  (press)="onContextMenu($event)"
  [style.backgroundColor]="backgroundColorCss"
>
  <!-- [(ngModel)]="fieldValue" -->
  <!--     si on ajoute ceci, ça call un fieldvaluechange et donc rend la data not sync => showTime="true" hourFormat="24" -->
  <iu-prime-calendar
    #calendar
    showButtonBar="true"
    [isStandalone]="this.isStandalone"
    [monthNavigator]="true"
    [yearNavigator]="true"
    yearRange="{{ this.rangeDate }}"
    [iupicsLocale]="locale"
    [label]="label"
    [isLabelDisplay]="this.isLabelDisplay"
    [showTime]="this.data?.needTime"
    [showIcon]="true"
    (fieldChange)="fieldChange($event)"
    (selectItemEmitter)="calloutEventChange()"
    [ngClass]="[hasConflict ? 'iu-field-conflict' : '']"
    [readonlyInput]="isReadOnly"
    [disabled]="isReadOnly"
    (checkGridRendererEmitter)="checkGridRenderer()"
    [isGridRenderer]="isGridRenderer"
    [columnName]="data ? data.columnName : columnName"
    [hasTodayBtn]="hasTodayBtn"
    [calendarConfig]="calendarConfig"
    (calendarConfigChange)="onCalendarConfigChange($event)"
  ></iu-prime-calendar>
  <!--  -->
  <i
    *ngIf="hasConflict && dataStored"
    #spanInput
    class="ui-inputgroup-addon rtconflict fas fa-exclamation-triangle iconClass iu-field-conflict-icon"
    (click)="showConflictPanel($event)"
    aria-hidden="true"
  ></i>
  <p-overlayPanel #opValuePref *ngIf="showValuePrefPanel"  [baseZIndex]="6000" [dismissable]="true" appendTo="body">
    <iu-value-preference-panel
      [overlayPanel]="opValuePref"
      [fieldValue]="fieldValue"
      [dataStored]="this.dataStored"
      [sourceComponent]="this"
      [data]="this.data"
      (closeEmitter)="toggleValuePreferencePanel($event)"
    ></iu-value-preference-panel>
  </p-overlayPanel>
</span>

<p-overlayPanel #opConflict>
  <a class="data-conflict" (click)="chooseData($event, (dataStored?.data)[data.columnName], true); opConflict.hide($event)">{{
    (dataStored?.data)[data.columnName]
  }}</a>
  | <a class="data-conflict" (click)="chooseData($event, conflictedData, false); opConflict.hide($event)">{{ conflictedData }}</a>
</p-overlayPanel>
