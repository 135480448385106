<span
  [style.display]="displayCss"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
  (contextmenu)="onContextMenu($event)"
  (press)="onContextMenu($event)"
  [pTooltip]="tooltip"
  life="3"
  tooltipPosition="top"
  showDelay="500"
  tooltipZIndex="2100"
  [style.backgroundColor]="backgroundColorCss"
>
  <label *ngIf="this.isLabelDisplay && !(displayImage && fieldValue !== undefined && fieldValue !== null)">{{
    label | textLimit: this.elementRef.nativeElement.firstElementChild.firstElementChild.clientWidth / 7
  }}</label>
  <input
    *ngIf="!(displayImage && fieldValue !== undefined && fieldValue !== null)"
    #input
    [(ngModel)]="fieldValue"
    [readonly]="isReadOnly ? 'disabled' : null"
    [tabindex]="isReadOnly ? '-1' : null"
    [ngClass]="[mandatoryCss || '', 'ui-corner-all']"
    value=""
    type="text"
    (change)="dataChange(input.value)"
    pInputText
    autocomplete="no"
    data-cy="input-text"
    [attr.data-cy-columnName]="data ? data.columnName : columnName"
  />
  <i
    *ngIf="!(displayImage && fieldValue !== undefined && fieldValue !== null)"
    class="picto fa-picture-o"
    aria-hidden="true"
    #ddBtn
    type="button"
  ></i>

  <img
    *ngIf="displayImage && fieldValue !== undefined && fieldValue !== null"
    [ngClass]="[!isReadOnly ? 'iu-image-hover' : '', 'iu-image']"
    [attr.width]="width"
    [attr.height]="height"
    [src]="srcImageError ? 'assets/imageError.webp' : fieldValue"
    (click)="switchField()"
    (error)="srcImageError = true"
    data-cy="input-image"
    [attr.data-cy-columnName]="data ? data.columnName : columnName"
  />
  <p-overlayPanel #opValuePref *ngIf="showValuePrefPanel" [baseZIndex]="6000"  [dismissable]="true" appendTo="body">
    <iu-value-preference-panel
      [overlayPanel]="opValuePref"
      [fieldValue]="fieldValue"
      [dataStored]="this.dataStored"
      [sourceComponent]="this"
      [data]="this.data"
      (closeEmitter)="toggleValuePreferencePanel($event)"
    ></iu-value-preference-panel>
  </p-overlayPanel>
</span>
