import {AfterViewInit, Component, ComponentFactoryResolver, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { TranslateService } from '@ngx-translate/core';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';
@Component({
  selector: 'iu-create-from-open-invoice-st-window-ui',
  templateUrl: './create-from-open-invoice-st-window-ui.component.html',
  styleUrls: ['./create-from-open-invoice-st-window-ui.component.scss']
})
export class CreateFromOpenInvoiceStWindowUiComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit {
  @ViewChild('left', { read: ViewContainerRef, static: true })
  vcrLeft: ViewContainerRef;
  @ViewChild('middle', { read: ViewContainerRef, static: true })
  vcrMiddle: ViewContainerRef;
  @ViewChild('right', { read: ViewContainerRef, static: true })
  vcrRight: ViewContainerRef;
  @ViewChild('bot', { read: ViewContainerRef, static: true })
  @Output()
  vcrBot: ViewContainerRef;
  SumSelectedRows = 0;
  countOfRows = 0;
  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
    this.isModal = false;
  }
  ngOnInit() {

    this.customDesignArray.push(
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_BPartner_ID',
        cssClass: 'p-col-6 p-md-4 p-lg-2'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'IsSOTrx',
        cssClass: 'p-col-6 p-md-4 p-lg-2'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_DocType_ID',
        cssClass: 'p-col-6 p-md-4 p-lg-2'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_BankStatementLine_ID',
        cssClass: 'p-col-6 p-md-4 p-lg-2'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_BankStatement_ID',
        cssClass: 'p-col-6 p-md-4 p-lg-2'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.GRID,
        tableName: 'Create From Open InvoiceST Table',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'Total',
        cssClass: 'p-col-6 p-md-4 p-lg-2'
      },
      {
        vcr: 'vcrButtons',
        type: CustomDesignItemType.FIELD,
        columnName: 'Processing',
        cssClass: 'p-col-6 p-md-4 p-lg-2'
      }
    );
    if (!this.dataStore) {
      this.dataStore = this.store.newSpecificWindowData(this.formId);
    }
    super.ngOnInit();
   if (!this.dataStore) {
    this.dataStore = this.store.newSpecificWindowData(this.formId);
  }
  if (this.parentComponent instanceof EditViewUiComponent) {
    this.showSpecificWindow();
  }
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
    // Global.workspace = this;
  }

   notifyFromRowSelected(rowSelected: any) {
    let total = 0;
    this.countOfRows = this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getModel().getRowCount();
    const lineSelecteds = this.getGridSelection('Create From Open InvoiceST Table');
    if (rowSelected.length === 0) {
      this.dataStore.data.c = this.getField('Total').data.defaultValue;
    }
    if (lineSelecteds && lineSelecteds.length > 0) {
      lineSelecteds.forEach((rowData) => {
        if (rowData['Open'] !== undefined && rowData['Open'] !== null) {
          total += rowData['Open'];
        }

      });
    }
    this.setFormStoreData('Total', total.toFixed(2));
    this.setDataContainersValueWithChangedStore();
  }
}