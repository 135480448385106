// TODO REMOVE AFTER RELEASE 3.10
import { CompiereDataGridFilterType, CompiereDataGridType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { DocserverWsResponse } from '@compiere-ws/models/docserverWsResponse';
import { IAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { UploadedFile } from '@iupics-components/models/uploaded-file';

export function getOperationDetail(Z_Production_Operation_ID: number, ad_language: string): DataStoreRequest {
  return getDataStoreRequest('Z_Production_Operation_ID', 'Z_Production_Operation', Z_Production_Operation_ID, ad_language);
}

export function getOPComponents(Z_Production_Operation_ID: number, ad_language: string): DataStoreRequest {
  return getDataStoreRequest('Z_Production_Operation_ID', 'Z_Production_OutLine', Z_Production_Operation_ID, ad_language);
}

export function getOPRessources(Z_Production_Operation_ID: number, ad_language: string): DataStoreRequest {
  return getDataStoreRequest('Z_Production_Operation_ID', 'Z_Production_OutTime', Z_Production_Operation_ID, ad_language);
}

export function getProducts(Z_Production_Operation_ID: number, ad_language: string): DataStoreRequest {
  return getDataStoreRequest('Z_Production_Operation_ID', 'Z_Production_InLine', Z_Production_Operation_ID, ad_language);
}

export function getTable(tableName: string, ad_language: string): DataStoreRequest {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'AD_Table',
      ad_language,
      filterModel: {
        TableName: {
          filterType: CompiereDataGridFilterType.TEXT,
          operators: [OperatorFilterType.EQUALS],
          values: [tableName]
        }
      }
    }
  };
}

export function getProcess(value: string, ad_language: string): DataStoreRequest {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'AD_Process',
      ad_language,
      filterModel: {
        Value: {
          filterType: CompiereDataGridFilterType.TEXT,
          operators: [OperatorFilterType.EQUALS],
          values: [value]
        }
      }
    }
  };
}

export function formatAttachments(response: DocserverWsResponse): UploadedFile[] {
  return response.entries.map(
    (hit) =>
      ({
        src: hit.url,
        name: hit.fileName,
        id: hit.id,
        docId: hit.docId,
        attachment_ID: hit.attachment_ID,
        extension: hit.fileName
          ? hit.fileName.split('.').length > 1
            ? hit.fileName.split('.')[hit.fileName.split('.').length - 1]
            : undefined
          : undefined,
        isDeletable: false
      } as UploadedFile)
  );
}

function getDataStoreRequest(columnName: string, tableName: string, id: number, ad_language: string): DataStoreRequest {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName,
      ad_language,
      filterModel: {
        [columnName]: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [[id]]
        }
      }
    }
  };
}

export interface Operation {
  components: OperationComponent[];
  ressources: OperationRessource[];
  attachedFile: UploadedFile[];
  description: string;
  deltaTime: string;
  note: string;
  status: IAutocomplete;
}

export interface OperationComponent {
  name: string;
  QtyConsumed: number;
  QtyToConsume: number;
  unit: string;
  usedQty: number;
  Z_Production_Out_ID: number;
  Z_Production_OutLine_ID: number;
}

export interface OperationRessource {
  name: string;
  baseTime: number;
  usedTime: number;
}

export interface FinalProduct {
  name: string;
  QtyProduced: number;
  QtyToProduce: number;
  unit: string;
  usedQty: number;
  Z_Production_In_ID: number;
  Z_Production_InLine_ID: number;
}
