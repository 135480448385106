import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { IupicsCookieService, LocalStorageIupics } from '@iupics-manager/managers/security-manager/cookies/iupics-cookie.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { Global } from '@iupics-manager/models/global-var';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { CompiereLanguage } from './models/compiere-language-json';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private connectorService: SecurityManagerService, private cookieService: IupicsCookieService,
    private translateService: TranslateService, private messageManager: MessageManagerService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    Global.activeXHRRequest++;
    if (this.connectorService.getOAuth2AccessToken()) {
      if (!request.headers.get('Authorization')) {
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + this.connectorService.getOAuth2AccessToken().access_token
          }
        });
      }
    }
    if (this.cookieService.checkLS(LocalStorageIupics.default_language)) {
      request = request.clone({
        setHeaders: {
          'X-Lang': `${
            (JSON.parse(this.cookieService.getDecryptedLocalStorage(LocalStorageIupics.default_language)) as CompiereLanguage)
              .iso_code
          }`
        }
      });
    } else if (this.connectorService.getIupicsUserAccount()) {
      if (this.connectorService.getIupicsUserAccount().default_language) {
        request = request.clone({
          setHeaders: {
            'X-Lang': `${this.connectorService.getIupicsUserAccount().default_language.iso_code}`
          }
        });
      }
    }
    Global.lastActivity = new Date();
    return next.handle(request).pipe(
      map((event) => {
        return event;
      }),
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            if (!request.url.includes('auth-server/session/token')) {
              this.cookieService.clearLsCookies();
              location.reload();
            }
          } else if (err.status === 422) {
            return throwError({
              name: this.translateService.instant('generic.error'),
              message: err.error.message
            });
          }
        }
        return throwError(err);
      }),
      finalize(() => {
        if (Global.activeXHRRequest > 0) {
          Global.activeXHRRequest--;
        }
      })
    );
  }
}
