import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { TranslateService } from '@ngx-translate/core';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-print-zone',
  templateUrl: './print-zone.component.html',
  styleUrls: ['./print-zone.component.scss']
})
export class PrintZoneComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('top', { read: ViewContainerRef, static: true })
  vcrTop: ViewContainerRef;

  /* Constructor */
  constructor(
    protected windowFactory: WindowFactoryService,
    protected resolver: ComponentFactoryResolver,
    protected uiCreator: UICreatorService,
    protected store: DataStoreService,
    protected processService: CompiereProcessService,
    protected socketService: SocketService,
    protected connectorService: SecurityManagerService,
    protected progressService: ProcessInProgressService,
    protected translateService: TranslateService,
    private messageManager: MessageManagerService,
    private renderer: Renderer2
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
  }
  ngOnInit(): void {
    this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
      specificWindow.items.forEach((item) => {
        if (item.data.columnName === 'XX_PrintZone_ID') {
          const fieldSize = 'p-col-12  p-md-6 p-md-offset-3';
          this.customDesignArray.push({
            vcr: 'vcrTop',
            type: CustomDesignItemType.FIELD,
            columnName: 'XX_PrintZone_ID',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'Processing') {
          const fieldSize = 'p-col-12 p-md-3 p-lg-2';
          this.customDesignArray.push({
            vcr: 'vcrButtons',
            type: CustomDesignItemType.FIELD,
            columnName: 'Processing',
            cssClass: fieldSize
          });
        }
      });
      const item: DynamicComponent = {
        container: this,
        DOMParentComponent: this,
        component: 'SpecificWindowUiComponent',
        cssClass: 'p-col-12',
        isCssOnComponent: false,
        tabId: this.formId,
        gridPaginator: false
      };
      this.windowFactory.newEventHandler({
        type: IupicsTypeEvent.showSpecificWindow,
        item: item
      });
    });
    this.componentRef.location.nativeElement.parentElement.style.overflow = 'unset';
  }

  processProcessing(item) {
    this.sourceComponent.runWFPrintZone(this.dataStore.data['XX_PrintZone_ID']);
    this.closeModalEmitter.emit();
  }

  close() {
    this.closeModalEmitter.emit();
  }
}
